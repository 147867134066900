import { Box, Typography } from "@mui/material";
import UnlimitedSupport from "../../images/UnlimitedSupport.png";
import UnlimitedTraffic from "../../images/UnlimitedTraffic.png";
import LowCost from "../../images/LowCost.png";
import React from "react";

const cardData = [
  {
    title: "Unlimited Support",
    Src: UnlimitedSupport,
    text: "We provide free initial website setup and ongoing Whatsapp support",
  },
  {
    title: "Unlimited Traffic",
    Src: UnlimitedTraffic,
    text: "Unlimited traffic is enabled by hosting into your own AWS account",
  },
  {
    title: "Low Cost",
    Src: LowCost,
    text: "Low monthly lease(SaaS) for small and medium businesses",
  },
];

const Card = ({ title, Src, text }) => {
  return (
    <Box className="col">
      <Box className="card h-100 border-0">
        <img
          src={Src}
          className="card-img-top"
          alt={title}
          style={{ width: "80px", height: "80px", margin: "auto" }}
        />
        <Box className="card-body">
          <Typography
            className="card-title "
            color="#011037"
            sx={{ fontWeight: "800", fontSize: "24px" }}
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            className="card-text"
            color="#011037"
            variant="body2"
            gutterBottom
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default function WhyChoose() {
  return (
    <>
      <div>
        <Typography fontWeight="bold" variant="h4" paddingTop={3} color= '#011037'gutterBottom>
          Why Choose Tansy Cloud?
        </Typography>
      </div>
      <Typography variant="body1" gutterBottom paddingBottom={5} color= '#64607D'>
        A one-stop software solution for all your business needs
      </Typography>
      <Box className="row row-cols-1 row-cols-md-3 g-4">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            Src={card.Src}
            text={card.text}
          />
        ))}
      </Box>
    </>
  );
}
