import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contact from "../pages/Contact/Contact";
import Home from "../pages/Home";
import Solutions from "../pages/Solutions";
import Pricing from "../pages/Pricing/Pricing";
import FAQs from "../pages/FAQs/FAQs";
import AboutUS from "../pages/AboutUs/AboutUs";
import NoMatchPage from "../pages/NoMatchPage/NoMatchPage";
import Automobile from "../pages/Automobile";
import B2B from "../pages/B2B";
import BlockChain from "../pages/BlockChain";
import RealEstate from "../pages/RealEstate";
import NGO from "../pages/NGO";
import Restaurant from "../pages/Restaurant";
import ServiceIndustry from "../pages/ServiceIndustry";
import AllServices from "../pages/AllServices";
import OnlineSales from "../pages/OnlineSales/OnlineSales";
import AssetManagement from "../pages/AssetManagement";
import Clinic from "../pages/Clinic";
import { SolutionsPage } from "../pages/Solutions";

export default function Routers() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact={true} path="/" element={<Home />} />
        <Route path="/solutions" element={<Solutions />}>
          <Route index element={<SolutionsPage />} />
          <Route path="automobile" element={<Automobile />} />
          <Route path="b2b" element={<B2B />} />
          <Route path="blockchain" element={<BlockChain />} />
          <Route path="real-estate" element={<RealEstate />} />
          <Route path="ngo" element={<NGO />} />
          <Route path="restaurant" element={<Restaurant />} />
          <Route path="online-sales" element={<OnlineSales />} />
          <Route path="service-industry" element={<ServiceIndustry />} />
          <Route path="allservices" element={<AllServices />} />
          <Route path="*" exact={true} element={<NoMatchPage />} />
          <Route path="asset-management" element={<AssetManagement />} />
          <Route path="clinic" element={<Clinic />} />
        </Route>
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/about-us" element={<AboutUS />} />
        <Route path="*" exact={true} element={<NoMatchPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}
