import { Box, Button, FormControlLabel } from "@mui/material";
import { Grid, Paper, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Card from "react-bootstrap/Card";
import CheckIcon from "@mui/icons-material/Check";
import { plans, typographyValues } from "../../data/Pricing";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";

export function Placement({ title, script }) {
  const typographyProps = {
    fontWeight: "bold",
    fontSize: { xs: "30px", md: "42px" },
    textAlign: "center",
    letterSpacing: "-0.035em",
    gutterBottom: true,
    color: "#011037",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "auto",
        marginBottom: "120px",
        margin: "auto",
        padding: "10px",
      }}
    >
      <Box sx={{ maxWidth: "700px", marginTop: "70px", marginBottom: "50px" }}>
        <Box paddingBottom="30px">
          <Typography {...typographyProps}>{title}</Typography>
          <Box padding={1}>
            <Typography variant="body1" color="#64607D" gutterBottom>
              {script}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default function Pricing() {
  React.useEffect(() => {
    document.title = "Tansy Cloud | Pricing";
  }, []);
  return (
    <div>
      <Box margin="10px">
        <Paper elevation={3} padding="10px">
          <Placement
            title={typographyValues.title}
            script={typographyValues.script}
          />
          <PlanSelection margin="auto" />
          {/* <AddOns /> */}
        </Paper>
      </Box>
    </div>
  );
}

function PlanSelection() {
  return (
    <Box display="flex" marging="auto">
      <Box margin="auto" maxWidth="1500px" padding="1px">
        <div className="plan-selection">
          <Grid container spacing="1px">
            {plans.map((plan, index) => (
              <Grid
                item
                key={index}
                display="flex"
                margin="auto"
                padding="10px"
              >
                <PlanBox plan={plan} />
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>
    </Box>
  );
}


function CardHeader({ title, description }) {
  return (
    <div style={{ paddingLeft: "20px", paddingTop: "12px" }}>
      <Typography
        className="plan-title"
        fontWeight={600}
        fontSize="28px"
        textAlign="left"
        paddingBottom="3px"
      >
        {title}
      </Typography>
      <Box width="250px">
        <Typography
          className="plan-description"
          fontWeight={400}
          fontSize="17px"
          textAlign="right"
          paddingRight="25px"
        >
          {description}
        </Typography>
      </Box>
    </div>
  );
}

function CardTitle({ price, duration }) {
  return (
    <Card.Title style={{ paddingLeft: "15px" }}>
      <Box textAlign="left">
        <Typography
          className="plan-price"
          fontWeight={600}
          fontSize="56px"
          textAlign="left"
          display="inline-block"
          mr={1}
        >
          {price}
        </Typography>
        <Typography
          className="plan-duration"
          fontWeight={400}
          fontSize="18px"
          textAlign="left"
          display="inline-block"
        >
          {duration}
        </Typography>
      </Box>
    </Card.Title>
  );
}

const StyledButton = styled(Button)({
  transition: "transform 0.3s ease",
  ":hover": {
    transform: "scale(1.04)",
  },
});

function SelectPlanButton({ backgroundColor, color }) {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("410"));
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 1000);
  };

  const buttonStyle = {
    width: isMatch ? "250px" : "250px",
    backgroundColor: clicked ? "#DDBEA9" : backgroundColor,
    borderRadius: "20px",
    height: isMatch ? "58px" : "50px",
  };
  return (
    <StyledButton
      style={buttonStyle}
      className="text-white"
      onClick={handleClick}
    >
      <Typography
        fontWeight={600}
        fontSize="18px"
        textAlign="center"
        color={color}
      >
        Select this Plan
      </Typography>
    </StyledButton>
  );
}

function PlanLabels({ labels, iconColor }) {
  return (
    <Card.Text style={{ paddingLeft: "20px" }}>
      {labels.map((label, index) => (
        <FormControlLabel
          key={index}
          label={label.name}
          control={
            <Checkbox
              icon={
                <span
                  style={{
                    backgroundColor: { iconColor },
                    borderRadius: "2px",
                    width: "16px",
                    height: "16px",
                    display: "inline-block",
                    fontWeight: "bold",
                  }}
                />
              }
              checkedIcon={
                <CheckIcon
                  style={{ width: "16px", height: "16px", fill: iconColor }}
                />
              }
              checked={label.checked}
            />
          }
          style={{
            display: "block",
            marginBottom: "8px",
            textAlign: "left",
            fontSize: "18px",
          }}
        />
      ))}
    </Card.Text>
  );
}

function PlanBox({ plan, index }) {
  const { title, description, price, labels, duration, id } = plan;
  const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
  const [fillColor, setFillColor] = useState("#fff");
  const [textColor, setTextColor] = useState("#181433");
  const [color, setColor] = useState("white");
  const [iconColor, setIconColor] = useState("black");
  const [backgroundColor, setBackgroundColor] = useState("#181433");
  const [fillStroke, setFillStroke] = useState("#c7d1c9");
  const [strokeWidth, setStrokeWidth] = useState(null);

  useEffect(() => {
    const handleMouseEnter = () => {
      setHoveredCardIndex(index);
      setFillColor("#338DD1");
      setBackgroundColor("white");
      setTextColor("white");
      setColor("black");
      setIconColor("white");
    };

    const handleMouseLeave = () => {
      setHoveredCardIndex(null);
      setFillColor("#fff");
      setBackgroundColor("#181433");
      setTextColor("#181433");
      setColor("white");
      setIconColor("black");

      if (id === 1 || id === 2 || id === 3 || id === 0) {
        setFillStroke("#338DD1");
        setStrokeWidth(1);
      } else {
        setFillStroke("#c7d1c9");
      }
    };

    const boxElement = document.getElementById(`box-${id}`);
    if (boxElement) {
      boxElement.addEventListener("mouseenter", handleMouseEnter);
      boxElement.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (boxElement) {
        boxElement.removeEventListener("mouseenter", handleMouseEnter);
        boxElement.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [id, index]);

  return (
    <Box
      sx={{ Width: "350px", height: "auto" }}
      style={{
        position: "relative",
        top: hoveredCardIndex === index ? "-15px" : "0",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <div className="plan-box">
        <Card className="text-black border-0 ">
          <Box id={`box-${id}`}>
            <CardImage
              fillColor={fillColor}
              fillStroke={fillStroke}
              strokeWidth={strokeWidth}
            />
            <Card.ImgOverlay>
              <Box
                container="true"
                direction="column"
                justifyContent="center"
                alignItems="center"
                paddingTop={1}
              >
                <Box style={{ padding: "5px", color: textColor }}>
                  <CardHeader
                    title={title}
                    description={description + "\u00A0"}
                  />
                  <hr />
                  <CardTitle price={price} duration={duration} />
                  <PlanLabels labels={labels} iconColor={iconColor} />
                </Box>
                <Box marginTop="20px">
                  <SelectPlanButton
                    backgroundColor={backgroundColor}
                    color={color}
                  />
                </Box>
              </Box>
            </Card.ImgOverlay>
          </Box>
        </Card>
      </div>
    </Box>
  );
}

// function AddOns() {
//   return (
//     <>
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         style={{ marginTop: "30px", marginBottom: "80px" }}
//       >
//         <Typography
//           textAlign="left"
//           display="inline-block"
//           mr={1}
//           fontWeight={800}
//           color="#011037"
//           fontSize="42px"
//           paddingRight={1}
//         >
//           Add-ons
//         </Typography>
//         <Button
//           variant="contained"
//           style={{
//             padding: "16px 80px",
//             width: "140px",
//             height: "39px",
//             background: "linear-gradient(180deg, #3062BE 0%, #328FD6 100%)",
//             borderRadius: "39px",
//             textTransform: "capitalize",
//           }}
//         >
//           <Typography
//             className="plan-duration"
//             fontWeight={400}
//             fontSize="18px"
//           >
//             Monthly
//           </Typography>
//         </Button>
//       </Box>
//       <Selection />
//     </>
//   );
// }

// function Selection() {
//   return (
//     <Box style={{ paddingBottom: "80px" }}>
//       <Box display="flex" margin="auto">
//         <Box margin="auto" maxWidth="1200px" padding={1}>
//           <Grid container="true" spacing={3}>
//             {/* {AddOnsData.map((data) => (
//               <Grid
//                 item
//                 key={data.id}
//                 display="flex-start"
//                 margin={{ md: "auto", lg: "0px", sm: "auto" }}
//                 padding={1}
//               >
//                 <AddOnsMisc data={data} />
//               </Grid>
//             ))} */}
//           </Grid>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// function AddOnsMisc({ data }) {
//   const { title, labels, id } = data;
//   const iconColorRef = useRef("black");

//   return (
//     <Box sx={{ margin: "auto" }}>
//       <Box id={id} sx={{ width: "340px", height: "auto" }}>
//         <Box>
//           <Card className="text-black border-0 ">
//             <Card.Body>
//               <Card.Text>
//                 <Typography
//                   fontWeight={1000}
//                   variant="h5"
//                   color="#011037"
//                   margin={4}
//                 >
//                   {title}
//                 </Typography>
//               </Card.Text>
//               <Card.Text>
//                 {labels.map((label, index) => (
//                   <Box
//                     key={index}
//                     sx={{ display: "flex", alignItems: "center", my: 1 }}
//                   >
//                     <CheckIcon
//                       style={{
//                         width: "16px",
//                         height: "16px",
//                         margin: "10px",
//                         fontWeight: "bold",
//                         fill: iconColorRef.current,
//                         mr: 1,
//                       }}
//                     />
//                     <Typography
//                       className="plan-duration"
//                       fontWeight={400}
//                       fontSize="17px"
//                       color="#181433"
//                     >
//                       {label.name}
//                     </Typography>
//                     <Typography
//                       className="plan-duration"
//                       fontWeight={1000}
//                       fontSize="19px"
//                       ml="auto"
//                     >
//                       {label.price}
//                     </Typography>
//                   </Box>
//                 ))}
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

function CardImage({ fillColor, fillStroke, strokeWidth }) {
  const encodedSvgMarkup = encodeURIComponent(`
   <svg width="303" height="913" viewBox="0 0 303 913" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
  d="M279.371 763.774
     L279.363 763.783
     L266.22 779.164
     C260.615 785.723 252.42 789.5 243.792 789.5
     H34
     C17.7076 789.5 4.5 776.292 4.5 760
     V30
     C4.5 13.7076 17.7076 0.5 34 0.5
     H269
     C285.292 0.5 298.5 13.7076 298.5 30
     V731.145
     C298.5 738.412 295.818 745.423 290.968 750.835
     L279.371 763.774"
  stroke="${fillStroke}"
  stroke-width="${strokeWidth}"
  fill="${fillColor}"
/>

    </svg>
  `);
  return (
    <Card.Img
      alt="Img"
      loading="lazy"
      position="relative"
      src={`data:image/svg+xml;utf8,${encodedSvgMarkup}`}
      height="880px"
      margin="5px"
    />
  );
}