import * as React from "react";
import Ellipse from "../../images/Ellipse.svg";
import SemiEllipse from "../../images/SemiEllipse.svg";
import Banner from "../../images/HomeBanner.png";
import Decorator from "../../images/Decorator.svg";
import Decorators from "../../images/DecoratorLeft.svg";
import Cloud1 from "../../images/Cloud-1.png";
import Cloud2 from "../../images/Cloud-2.png";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "react-bootstrap/Card";
import { Box, CardMedia } from "@mui/material";
import { BusinessData, sectionsData } from "../../data/Home";
import { Button, Container, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function index() {
  return (
    <div>
      <HomeBanner />
      <Box margin="10px">
        <Paper elevation={1} padding="10px">
          <Softwaresolutions />
          <BusinessesWeSupport />
        </Paper>
      </Box>
    </div>
  );
}

const StyledPaper = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: 750,
  color: theme.palette.text.primary,
}));
function HomeBanner() {
  React.useEffect(() => {
    document.title = "Tansy Cloud | Software Solutions";
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <Box
        position="relative"
        className="card"
        minHeight={{ xs: "370px", md: "640px" }}
        style={{
          background: "linear-gradient(180deg, #3062BE 0%, #328FD6 100%)",
          margin: "auto",
          maxHeight: "650px",
          borderRadius: "0px",
          border: "0px",
          top: "-1px",
        }}
      >
        <Box
          position="absolute"
          sx={{
            marginLeft: "auto",
            marginTop: "0",
            width: "174px",
            height: "174px",
            right: "0px",
            top: "-100px",
          }}
        >
          <CardMedia
            className="card-img "
            component="img"
            image={SemiEllipse}
            alt="Img"
            loading="lazy"
            position="relative"
          />
        </Box>
        <Container fluid sx={{ margin: "auto" }}>
          <Container style={{ position: "relative" }}>
            <Box
              style={{
                marginTop: "-130px",
                padding: "0px 10px",
                opacity: 0.8,
                paddingBottom: "100px",
              }}
            >
              <CardMedia
                className="card-img "
                component="img"
                height="auto"
                image={Ellipse}
                alt="Img"
                loading="lazy"
                position="relative"
                style={{ zIndex: -1 }}
              />
            </Box>
          </Container>
          <Box
            class="card-img-overlay"
            sx={{ flexGrow: 1, overflow: "hidden", px: 1 }}
          >
            <StyledPaper sx={{ my: 1, mx: "auto", p: 1 }}>
              <Grid container wrap="nowrap" spacing={0.5}>
                <Grid item xs>
                  <Box paddingTop={4}>
                    <Typography
                      gutterBottom
                      fontSize={{ xs: "30px", md: "64px" }}
                      className="text-white text-center"
                      fontWeight={{ xs: "600", md: "600" }}
                      lineHeight="105%"
                      letterSpacing="0.036em"
                      sx={{
                        display: "block",
                        textAlign: "center",
                        // fontFamily: "Manrope",
                        fontStyle: "normal",
                      }}
                    >
                      Software applications designed to digitize
                      <br /> your business.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </StyledPaper>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item xs marginBottom={2} marginTop={-2}>
                <Typography
                  noWrap
                  gutterBottom
                  color="white"
                  lineHeight="15px"
                  fontSize={{ xs: "13px", md: "18px" }}
                >
                  Tailored software for SMEs, meeting all business needs.
                </Typography>
              </Grid>
            </Grid>
            <StyledPaper sx={{ mx: "auto", p: 1 }}>
              <Grid container wrap="nowrap" spacing={1}>
                <Grid item xs marginBottom={3}>
                  <Button
                    sx={style}
                    variant="contained"
                    onClick={() => navigate("/contact-us")}
                  >
                    Request a Demo
                  </Button>
                </Grid>
              </Grid>
            </StyledPaper>
            <Container maxWidth="md">
              <Box position="relative" maxWidth="780px" margin="auto">
                <Box
                  position="absolute"
                  top={{ xs: "-36px", md: "-85px" }}
                  left="-13px"
                  style={{ zIndex: 1 }}
                  width={{ xs: "60px", md: "150px" }}
                  height={{ xs: "60px", md: "150px" }}
                >
                  <CardMedia
                    component="img"
                    image={Cloud1}
                    alt="Img"
                    loading="lazy"
                  />
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CardMedia
                    component="img"
                    image={Banner}
                    alt="Img"
                    loading="lazy"
                  />
                </Box>
                <Box
                  position="absolute"
                  bottom="-10px"
                  right="-20px"
                  width={{ xs: "45px", md: "95px" }}
                  height={{ xs: "45px", md: "95px" }}
                >
                  <CardMedia
                    component="img"
                    image={Cloud2}
                    alt="Img"
                    loading="lazy"
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        </Container>
      </Box>
    </>
  );
}

function Softwaresolutions() {
  const HeaderSection = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "auto",
        marginBottom: "90px",
      }}
    >
      <Box
        maxWidth={{ xs: "350px", md: "750px" }}
        marginTop={{ xs: "90px", md: "230px" }}
        padding={{ xs: "10px", md: "5px" }}
        flex={1}
      >
        <Typography
          variant="h1"
          fontWeight="bold"
          fontSize={{ xs: "30px", md: "42px" }}
          lineHeight={{ xs: "36px", md: "60px" }}
          textAlign="center"
          letterSpacing="-0.03em"
          gutterBottom
          color="#011037"
        >
          Software Solutions
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          color="#64607D"
          fontSize={{ xs: "17px", md: "19px" }}
        >
          Tansy Cloud unifies 50+ business modules, erasing software integration
          needs with an all-in-one SaaS solution.
        </Typography>
      </Box>
    </Box>
  );

  const DecoratorSection = () => (
    <Box
      sx={{
        marginLeft: "auto",
        width: "150px",
        height: "300px",
        marginTop: "-300px",
      }}
    >
      <CardMedia
        className="card-img"
        component="img"
        image={Decorator}
        alt="Img"
        loading="lazy"
        position="relative"
      />
    </Box>
  );

  return (
    <React.Fragment>
      <HeaderSection />
      <DecoratorSection />
      <BusinessesFrames data={sectionsData} />
    </React.Fragment>
  );
}

function BusinessesFrames({ data }) {
  const [hoveredCardIndex, setHoveredCardIndex] = React.useState(null);
  const handleMouseEnter = (index) => {
    setHoveredCardIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredCardIndex(null);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        maxWidth: "1200px",
        margin: "auto",
      }}
    >
      <Grid
        container="true"
        spacing={{ xs: 1, md: 1 }}
        sx={{ justifyContent: "center" }}
      >
        {data.map(({ frame, frameColor, frameIcon, title, text }, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              className="d-flex justify-content-center align-items-center text-dark"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              style={{
                position: "relative",
                top: hoveredCardIndex === index ? "-10px" : "0",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Box
                className="position-relative mb-2 mt-2"
                style={{ width: "330px", height: "250px" }}
              >
                <Card className="border-0">
                  <Card.Img
                    style={{
                      filter:
                        hoveredCardIndex === index ? "brightness(95%)" : "",
                      transition: "filter 0.3s ease-in-out",
                    }}
                    variant="top"
                    src={frame}
                    alt={title}
                    loading="lazy"
                  />
                  <Card.ImgOverlay
                    style={{ textAlign: "left", marginTop: "-70px" }}
                  >
                    <Card.Body>
                      <Box
                        className="position-relative"
                        style={{ width: "68px", height: "70px" }}
                      >
                        <Card.Img
                          className="card-img"
                          src={frameColor}
                          alt={title}
                          loading="lazy"
                        />
                        <Card.ImgOverlay>
                          <Card.Img
                            className="card-img"
                            src={frameIcon}
                            alt="Img"
                            loading="lazy"
                          />
                        </Card.ImgOverlay>
                      </Box>
                      <Card.Title className="fw-bold pb-3 pt-3">
                        {title}
                      </Card.Title>
                      <Card.Text
                        className="d-flex"
                        style={{ color: "#757095" }}
                      >
                        {text}
                      </Card.Text>
                    </Card.Body>
                  </Card.ImgOverlay>
                </Card>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export function WeSupport({ data }) {
  const [hoveredCardIndex, setHoveredCardIndex] = React.useState(null);
  const handleMouseEnter = (index) => {
    setHoveredCardIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredCardIndex(null);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        maxWidth: "1150px",
        margin: "auto",
        paddingTop: "30px",
        paddingBottom: "100px",
      }}
    >
      <Grid
        container="true"
        spacing={{ xs: 1, md: 1 }}
        sx={{ justifyContent: "center" }}
      >
        {data.map(({ frame, frameIcon, title, text }, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              className="d-flex justify-content-center align-items-center text-dark"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              style={{
                position: "relative",
                top: hoveredCardIndex === index ? "-10px" : "0",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <Box
                className="position-relative mb-2 mt-2"
                style={{ width: "340px", height: "330px" }}
              >
                <Card className="border-0">
                  <Card.Img
                    style={{
                      filter:
                        hoveredCardIndex === index ? "brightness(95%)" : "",
                      transition: "filter 0.3s ease-in-out",
                    }}
                    variant="top"
                    src={frame}
                    alt={title}
                    loading="lazy"
                  />
                  <Card.ImgOverlay style={{ textAlign: "left" }}>
                    <Card.Body style={{ paddingBottom: "0px" }}>
                      <Box
                        className="position-relative"
                        style={{ width: "60px", height: "60px" }}
                      >
                        <Card.Img
                          className="card-img"
                          src={frameIcon}
                          alt={title}
                          loading="lazy"
                        />
                      </Box>
                      <Card.Title className="fw-bold pb-3 pt-3">
                        {title}
                      </Card.Title>
                      <Card.Text
                        style={{
                          lineHeight: "30px",
                          letterSpacing: "-0.02em",
                          color: " #757095",
                        }}
                      >
                        <Box
                          zeroMinWidth
                          sx={{
                            flexGrow: 1,
                            overflow: "hidden",
                            maxWidth: "100%",
                          }}
                        >
                          {text}
                        </Box>
                      </Card.Text>
                    </Card.Body>
                  </Card.ImgOverlay>
                </Card>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function BusinessesWeSupport() {
  const HeaderSection = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "auto",
        marginBottom: "120px",
      }}
    >
      <Box
        maxWidth={{ xs: "350px", md: "780px" }}
        marginTop={{ xs: "20px", md: "60px" }}
        padding={{ xs: "10px", md: "5px" }}
        flex={1}
      >
        <Typography
          fontWeight="bold"
          fontSize={{ xs: "30px", md: "42px" }}
          color="#011037"
          gutterBottom
        >
          Businesses we support.
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          style={{ color: "#64607D" }}
          fontSize={{ xs: "17px", md: "19px" }}
        >
          Our goal is to deliver advanced technology like data science, AI, and
          blockchain, customized for over 100+ SME businesses.
        </Typography>
      </Box>
    </Box>
  );

  const DecoratorSection = () => (
    <Box
      sx={{
        marginRight: "auto",
        width: "160px",
        height: "300px",
        marginTop: "-350px",
      }}
    >
      <CardMedia
        className="card-img"
        component="img"
        image={Decorators}
        alt="Img"
        loading="lazy"
        position="relative"
      />
    </Box>
  );

  return (
    <React.Fragment>
      <HeaderSection />
      <DecoratorSection />
      <WeSupport data={BusinessData} />
    </React.Fragment>
  );
}
const style = {
  padding: "12px 28px",
  color: "black",
  background: "#FFFFFF",
  boxShadow: (
    "0px 100px 80px rgba(56, 56, 56, 0.1), " +
    "0px 64.8148px 46.8519px rgba(56, 56, 56, 0.0759259), " +
    "0px 38.5185px 25.4815px rgba(56, 56, 56, 0.0607407), " +
    "0px 20px 13px rgba(56, 56, 56, 0.05), " +
    "0px 8.14815px 6.51852px rgba(56, 56, 56, 0.0392593), " +
    "0px 1.85185px 3.14815px rgba(56, 56, 56, 0.0240741)"
  ),
  borderRadius: "20px",
  fontSize: "18px",
  fontWeight: "bold",
  textTransform: "capitalize",
  cursor: "pointer",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-3px)",
    boxShadow: (
      "0px 20px 20px rgba(56, 56, 56, 0.1), " +
      "0px 15px 15px rgba(56, 56, 56, 0.0759259), " +
      "0px 10px 10px rgba(56, 56, 56, 0.0607407), " +
      "0px 5px 5px rgba(56, 56, 56, 0.05), " +
      "0px 2px 2px rgba(56, 56, 56, 0.0392593), " +
      "0px 1px 1px rgba(56, 56, 56, 0.0240741)"
    ),
  },
  "@media (max-width: 600px)": {
    padding: "9px 20px",
    fontSize: "13px",
    color: "black",
    background: "#FFFFFF",
    boxShadow: (
      "0px 100px 80px rgba(56, 56, 56, 0.1), " +
      "0px 64.8148px 46.8519px rgba(56, 56, 56, 0.0759259), " +
      "0px 38.5185px 25.4815px rgba(56, 56, 56, 0.0607407), " +
      "0px 20px 13px rgba(56, 56, 56, 0.05), " +
      "0px 8.14815px 6.51852px rgba(56, 56, 56, 0.0392593), " +
      "0px 1.85185px 3.14815px rgba(56, 56, 56, 0.0240741)"
    ),
    borderRadius: "20px",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "translateY(-3px)",
      boxShadow: (
        "0px 20px 20px rgba(56, 56, 56, 0.1), " +
        "0px 15px 15px rgba(56, 56, 56, 0.0759259), " +
        "0px 10px 10px rgba(56, 56, 56, 0.0607407), " +
        "0px 5px 5px rgba(56, 56, 56, 0.05), " +
        "0px 2px 2px rgba(56, 56, 56, 0.0392593), " +
        "0px 1px 1px rgba(56, 56, 56, 0.0240741)"
      ),
    },
  },
};
