import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import { Box } from "@mui/material";

const value = "true";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

export default function KeyFeature({ data }) {
  return (
    <>
      <Typography fontSize="34px" fontWeight="bold" padding={5} gutterBottom>
        Software Components (modules)
      </Typography>
      {data.map(({ id, image, title, items }, sectionIndex) => (
        <Grid
          key={id}
          container="true"
          direction={sectionIndex % 2 === 0 ? "row" : "row-reverse"}
          spacing={2}
          paddingBottom={5}
        >
          <Grid
            item={Boolean(value)}
            xs={12}
            sm={6}
            justifyContent={sectionIndex % 2 === 0 ? "flex-start" : "flex-end"}
            alignItems="center"
          >
            <ButtonBase sx={{ width: 270, height: 210 }}>
              <Img alt={title} src={image} />
            </ButtonBase>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container="true"
            direction="column"
            spacing={2}
          >
            <Grid item xs>
              <Box padding={1}>
                <Typography
                  fontWeight="500px"
                  textAlign="left"
                  component="h1"
                  fontSize="26px"
                  paddingBottom={1}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  textAlign="left"
                  component="ul"
                  sx={{ paddingLeft: 4 }}
                >
                  {items.map((item, i) => (
                    <Typography
                      key={i}
                      component="li"
                      fontSize="15.5px"
                      lineHeight="24px"
                      color={"#575757"}
                    >
                      {item}
                    </Typography>
                  ))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
