import EcommerceIcon from "../images/EcommerceIcon.svg";
import RewardsIcon from "../images/RewardsIcon.svg";
import InventoryIcon from "../images/InventoryIcon.svg";
import ServiceIcon from "../images/ServiceIcon.svg";
import AccountingIcon from "../images/AccountingIcon.svg";

export const sectionsData = [
  {
    id: 1,
    frameIcon: RewardsIcon,
    title: "Rewards",
    items: [
      "Reward Programs for large shops",
      "Define rules for coupons, cash back and points",
      "Customers can login to redeem these coupons or points.",
      "Generate coupons by geo locations, cummulative purchase or single purchase events.",
    ],
  },
  {
    id: 2,
    frameIcon: ServiceIcon,
    title: "Service",
    items: [
      "Service Module to manage end-to-end operations.",
      "Automatic reminders.",
      "Rewards modile",
      "Easy customer tracking.",
    ],
  },
  {
    id: 3,
    frameIcon: InventoryIcon,
    title: "Inventory",
    items: [
      "Track and manage items through various stages along the purchase, stock and sales.",
      "Stock levels, product categorization and barcode scanning",
      "Defining product bundles, pricing and discounts.",
      "Manage stock increases, stock settlements, and unavailable status",
      "Bulk pricing and bulk stock functionality screens.",
    ],
  },
  {
    id: 4,
    frameIcon: AccountingIcon,
    title: "Accounting",
    items: [
      "Make payments to suppliers and accept receivables from clients, and merge multiple invoices into one payment.",
      "Automatically receive payment receipts in PDF format via E-mail or WhatsApp.",
      "Make sure that your clients are reminded to make payments via SMS, email, or Whatsapp.",
      "Checking future bank cash levels to prevent check bounces.",
    ],
  },
  {
    id: 5,
    frameIcon: EcommerceIcon,
    title: "Online Shopping Cart",
    items: [
      "Simple, clear and appealing to the human eye.",
      "Mobile-friendly.",
      "Business app, which eliminates the need for static web development.",
      "Tools to set up dynamic product filter engine that suit your business domains and products, providing user-friendly navigation.",
    ],
  },
];
