import { Accordion, AccordionItem } from "@chakra-ui/react";
import { AccordionPanel, AccordionButton } from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import MinusIcon from "@mui/icons-material/Remove";
import { Box, Paper, Typography } from "@mui/material";
import {
  GeneralData,
  SupportData,
  MobileApplicationData,
} from "../../data/FAQs";
import * as React from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

function FAQsScreen({ FAQsData }) {
  return (
    <Box width={{ base: "100%", md: "650px" }} margin="auto">
      {FAQsData.map((faq, index) => (
        <Accordion allowMultiple key={index}>
          <AccordionItem
            margin={9}
            key={index}
            style={{
              background: "#E7EBEE",
              border: "1px solid #E7EBEE",
              borderRadius: "10px",
            }}
          >
            {({ isExpanded }) => (
              <>
                <h2>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <AccordionButton
                      className="text-black border-0 "
                      flex="1"
                      textAlign="left"
                      fontSize="18px"
                      background="#FAFAFA"
                      color="#263238"
                    >
                      <Box fontSize="25px" padding="20px">
                        {isExpanded ? (
                          <MinusIcon
                            style={{
                              fill: " red",
                            }}
                          />
                        ) : (
                          <AddIcon />
                        )}
                      </Box>
                      {faq.question}
                    </AccordionButton>
                  </Box>
                </h2>
                <AccordionPanel pb={4}>
                  <Box margin="10px" display="flex">
                    <Paper elevation={3} padding="10px" width="100%">
                      <AccordionButton
                        fontSize="25px"
                        className="text-black border-0 bg-white "
                        style={{
                          alignItems: "flex-start",
                        }}
                      >
                        <Box padding="10px">
                          {isExpanded ? (
                            <MinusIcon
                              style={{
                                fill: " white",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                        <Box as="span" flex="1" textAlign="left" padding={2}>
                          <Typography
                            style={{
                              fontWeight: "light",
                              fontSize: "15px",
                              fontStyle: "normal",
                              display: "flex",
                              color: "#575757",
                            }}
                          >
                            {faq.answer}
                          </Typography>
                        </Box>
                      </AccordionButton>
                    </Paper>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      ))}
    </Box>
  );
}
export default function Tabs() {
  React.useEffect(() => {
    document.title = "Tansy Cloud | FAQs";
  }, []);
  const tabsData = [
    { eventKey: "first", text: "General" },
    { eventKey: "second", text: "Support" },
    { eventKey: "third", text: "Mobile Application", width: "130px" },
  ];

  return (
    <Box margin="7px">
      <Paper elevation={3} padding="10px">
        <Box
          paddingBottom={15}
          paddingTop="90px"
          maxWidth="1200px"
          margin="auto"
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "42px",
              display: "flex",
              padding: "1px 5px",
              marginBottom: "40px",
              justifyContent: "center",
              color: "#011037",
            }}
          >
            Frequently Asked Questions
          </Typography>
          <Box sx={{ height: "auto" }}>
            <Tab.Container
              id="left-tabs-example"
              className="mb-3"
              justify
              defaultActiveKey="first"
            >
              <div className="flex-column">
                <div className="flex-row" sm={3}>
                  <Box
                    margin="auto"
                    padding="10px"
                    width="auto"
                    marginBottom={2}
                  >
                    <Nav variant="pills" className="D-flex flex-row justify-content-center">
                      {tabsData.map((tab, index) => (
                        <Nav.Item
                          key={index}
                          style={{
                            margin: "10px 10px",
                            background: "#EFEFEF",
                            borderRadius: "10px",
                          }}
                        >
                          <Nav.Link eventKey={tab.eventKey}>
                            <Typography
                              style={{
                                fontWeight: "light",
                                fontSize: "14px",
                                display: "flex",
                                padding: "1px",
                                margin: "2px",
                                width: tab.width,
                                justifyContent: "center",
                              }}
                            >
                              {tab.text}
                            </Typography>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Box>
                </div>
                <div className="flex-row " sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <FAQsScreen FAQsData={GeneralData} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <FAQsScreen FAQsData={SupportData} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <FAQsScreen FAQsData={MobileApplicationData} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </Box>{" "}
        </Box>{" "}
      </Paper>
    </Box>
  );
}
