export const GeneralData = [
  {
    question: "  What is tansycloud ? ",
    answer:
      "Tansy Cloud can help you digitize your business and operations in totality, from cloud documents, client communication, reward programs, and e-commerce automation. ",
  },
  {
    question: " Why should I use tansycloud ? ",
    answer:
      "One stop shop for all your software needs, that includes multiple business domains like sales, service, restaurant, opticals, transport, daycare, NGOs and others.",
  },
  {
    question: "Which tansycloud plan is right for me ?",
    answer:
      "Select Business app package to manage your organisation and backoffice operations, Select consumer app package to host your ecommerce and online web portal. You can as well select both the packages.",
  },
  {
    question: "What does tansycloud integrate with ?",
    answer:
      "SMS, E-mail, WhatsApp, Payment Gateway and AWS Cloud document storage are integrated into our applications.",
  },
  {
    question: "Can I change my tansycloud plan ?",
    answer:
      "You can make changes to your plan at any time by changing your plan type and adding/removing users (note that post the refund period, we offer no refunds for downgrades).",
  },

  {
    question: "Can I use my own domain ? ",
    answer: "Yes, you can and you need to use your own domain.",
  },
  {
    question: "What is a payment gateway ? ",
    answer:
      "A payment gateway is payment processing technology that collect payments from your customer and deposits into your bank account.",
  },
  {
    question: "Are my documents secure with tansycloud ? ",
    answer:
      "We store all your business documents, invoices, recepits, product images and website images in your own AWS S3 account.",
  },

  {
    question: "Is my business too small to use tansycloud ?",
    answer:
      "We are specialised in working with small business and family owned businesses.",
  },
];

export const SupportData = [
  {
    question: " What is your support plan ? ",
    answer: "We do provide unlimited free of charge support over whatsapp.",
  },
  {
    question: "Do you charge for customisation ?  ",
    answer:
      "We do not charge for customisation as long as involved functionality holds value to our global customers.",
  },
];

export const MobileApplicationData = [
  {
    question: " Is Tansy Cloud platform mobile-ready ? ",
    answer:
      "Absolutely. All stores built on Tansy Cloud platform are Progressive Web Apps (PWA) and are powered by mobile-first architecture.",
  },
  {
    question: "Do you have support for building mobile apps for my store ? ",
    answer: "Please contact our support team.",
  },
];
