import BlockChainProductAuthentication from "../images/BlockChainProductAuthentication.svg";
import BlockChainContracts from "../images/BlockChainContracts.svg";

export const Data = {
  Title: "Tansy Blockchain",
  subTitle: "Coming Soon",
  Btn: [
   { label: "Back to Solutions", nav: "/solutions/allservices" },
    { label: "Schedule a Demo", nav: "/contact-us" },
  ],
};

export const sectionsData = [
  {
    id: 1,
    title: `BlockChain Product Authentication`,
    image: BlockChainProductAuthentication,
    items: [
      "The terms extra virgin olive oil, organic coffee, iphones, nike shoes are all familiar to us, but how can we be sure these claims are genuine?",
      "Did you know that more organic food is sold than produced? Many products are mislabeled or have misleading labels.",
      "More and more consumers are demanding more transparency regarding the goods they buy, whether it's sustainability, ethical sourcing, or authenticity.",
      "Supply chain transparency and product verification/authentication has been greatly enhanced by combining enterprise blockchain technology with sensors and Internet of Things (IoT) devices.",
      "With the advancement of blockchain technology, premium olive oil or organic coffee can now be verified quickly for authenticity",
      "As a result of BlockChain, entire value chains will be able to establish instantaneous consumer trust",
    ],
  },
  {
    id: 2,
    title: "BlockChain Contracts (digital signature)",
    image: BlockChainContracts,
    items: [
      "Using blockchain technology to manage contracts is the next generation of contract management.",
      "Business app.",
      "In the future, the digitization of paper-based contracts onto the blockchain could revolutionize how contracts are prepared, transacted, amended, stored, agreed on, and complied with.",
    ],
  },
];
