
export const typographyValues = {
  title: "Monthly Plans",
  script:
    "The first ten users are provided at no cost, after which there is a charge of $5 each. The price covers hosting for standard and silver, whereas hosting for gold and diamond will be overseen within dedicated personal hosting account.",
};

export const plans = [
  {
    id: 0,
    title: "Standard",
    description:  "",
    price: "$350",
    duration: "/month",
    labels: [
      { checked: true, name: "E-Commerce" },
      { checked: true, name: "Inventory" },
      { checked: true, name: "Accounting" },
      { checked: true, name: "Order Management" },
      { checked: true, name: "Cloud Documents" },
      { checked: true, name: "SMS" },
      { checked: true, name: "Email" },
      { checked: true, name: "Appointments" },
      { checked: true, name: "Supoort Tickets" },
    ],
  },

  {
    id: 1,
    title: "Silver",
    description: "Standard+",
    price: "$500",
    duration: "/month",
    labels: [
      { checked: true, name: "WhatsApp" },
      { checked: true, name: "Assets" },
      { checked: true, name: "Marketing Campaigns" },
      { checked: true, name: "Consumer Surveys" },
      { checked: true, name: "Rewards" },
      { checked: true, name: "Referrals" },
      { checked: true, name: "CRM" },
      { checked: false, name: null },
      { checked: false, name: null },
    ],
  },
  {
    id: 2,
    title: "Gold",
    description: "Silver+",
    price: "$600",
    duration: "/month",
    labels: [
      { checked: true, name: "Workflow" },
      { checked: true, name: "Mutli facility" },
      { checked: true, name: "Data warehouse" },
      { checked: true, name: "Predictive Analytics" },
      { checked: false, name: "(+$50 per branch)" },
      { checked: false, name: null },
      { checked: false, name: null },
      { checked: false, name: null },
      { checked: false, name: null },
    ],
  },
  {
    id: 3,
    title: "Diamond",
    description: "Gold+",
    price: "$750",
    duration: "/month",
    labels: [
      { checked: true, name: "BlackChain" },
      { checked: true, name: "Mutli E-Commerce sites" },
      { checked: false, name: "(+ $50 per E-Commerce" },
      { checked: false, name: "  domian)" },
      { checked: false, name: null },
      { checked: false, name: null },
      { checked: false, name: null },
      { checked: false, name: null },
      { checked: false, name: null },
    ],
  },
];
// export const AddOnsData = [
//   {
//     id: 0,
//     title: "Business App",
//     labels: [
//       { checked: true, name: "Per branch/location", price: "$100" },
//       { checked: true, name: "Additional user", price: "$30" },
//     ],
//   },
//   {
//     id: 1,
//     title: "Consumer App",
//     labels: [{ checked: true, name: "Digital Marketing/SEO", price: "$100" }],
//   },
//   {
//     id: 2,
//     title: "Misc.",
//     labels: [
//       { checked: true, name: "Per manufacturing unit", price: "$1000" },
//       { checked: true, name: "Per 1+M revenue", price: "$500" },
//       { checked: true, name: "Backoffice resource", price: "$750" },
//       { checked: true, name: "Creative Director", price: "$1500" },
//     ],
//   },
// ];
