import Service from "../images/Service.svg";
import Rewards from "../images/Rewards.svg";
import Appointment from "../images/Appointment.svg";
import Reminder from "../images/Reminder.svg";
import Communication from "../images/Communication.svg";
import CRM from "../images/CRM.svg";
import CloudDocuments from "../images/CloudDocuments.svg";
import AdminSettings from "../images/AdminSettings.svg";
import Organisation from "../images/Organisation.svg";
import SEODigitalMarketing from "../images/SEODigitalMarketing.svg";
import OnlineShoppingCart from "../images/OnlineShoppingCart.svg";
import OrderManagement from "../images/OrderManagement.svg";
import PaymentGateway from "../images/PaymentGateway.svg";
import Inventory from "../images/Inventory.svg";
import Accounting from "../images/Accounting.svg";
import Presales from "../images/Presales.svg";
import Sales from "../images/Sales.jpg";
import PostSales from "../images/PostSales.svg";
import SupportTicket from "../images/SupportTicket.svg";
import FoodOrders from "../images/FoodOrders.svg";
import NGO from "../images/NGO.svg";
import Tracking from "../images/Tracking.jpg";
import School from "../images/School.svg";
import BlockChainProductAuthentication from "../images/BlockChainProductAuthentication.svg";
import BlockChainContracts from "../images/BlockChainContracts.svg";

export const sectionsData = [
  {
    id: 1,
    title: "Admin & Security",
    image: AdminSettings,
    items: [
      "Total control ensures authorized access to sensitive information.",
      "Manage screen permissions through security groups for system users.",
      "Monitor employee productivity and identifying any unusual behavior.",
      "Values for system-wide drop downs can be added, deleted, or updated.",
      "You can add custom fields to selected screens, such as leads and clients.",
      "Automated backups, ensuring that your critical business data is safe.",
    ],
  },
  {
    id: 2,
    title: "Reports and Dashboards",
    image: PostSales,
    items: [
      "Derive Informed decisions from our reports and dashboards.",
      "Inventory: Stay on top of stock levels and orders.",
      "Sales: Track sales performance, products, and team.",
      "Finance: Monitor revenue, expenses, and ratios.",
      "Customer: Improve retention with behavior insights.",
      "CRM: Organize, analyze, and optimize customer interactions.",
      "Social Media: Gauge engagement and campaign impact.",
      "Web: Understand website traffic and user behavior.",
      "Marketing: Analyze campaigns, conversion rates, and ROI.",
      "Project Management: Monitor progress, resources, and budget.",
    ],
  },
  {
    id: 3,
    title: "Data Science (Predective Analytics)",
    image: Inventory,
    items: [
      "Data science enables precise targeting, resource allocation, and insights.",
      "Segment audience by behavior, enabling precise targeting with data tools.",
      "Churn Prediction: Proactively retain clients with data-driven strategies.",
      "Ensure optimal inventory levels with precise data analysis.",
    ],
  },
  {
    id: 4,
    title: "Organisation",
    image: Organisation,
    items: [
      "Streamline operations and empower your team to achieve more.",
      "Centralize data, automate, and enhance team collaboration.",
      "Efficiently manage client, lead, and supplier details, workflows, and more.",
      "Monitor expenses incurred by departments, employees, clients and vehicles.",
      "Enable the Google Map tracking feature for field agents.",
      "Employees, leads, clients, suppliers, and equipments seamlessly interconnect.",
    ],
  },
  {
    id: 5,
    title: "HR",
    image: SupportTicket,
    items: [
      "Streamline operations and empower your team to achieve more.",
      "Centralize data, automate, and enhance team collaboration.",
      "Efficiently manage client, lead, and supplier details, workflows, and more.",
      "Monitor expenses incurred by departments, employees, clients and vehicles.",
      "Enable the Google Map tracking feature for field agents.",
      "Employees, leads, clients, suppliers, and equipments seamlessly interconnect.",
    ],
  },
  {
    id: 6,
    title: "Workflow (automation)",
    image: Tracking,
    items: [
      "Streamline tasks, reduce manual effort, and save time.",
      "Tailor automation to your unique business processes.",
      "Minimize mistakes and improve accuracy with automated processes.",
      "Track progress, identify bottlenecks, and make informed decisions.",
      "Ocus on strategic initiatives while repetitive tasks run automatically.",
      "Stay competitive with advanced workflow automation.",
      "Maximize resources, cut down on administrative costs, and boost ROI.",
      "Optimize your workflow and experience the benefits today!",
    ],
  },
  {
    id: 7,
    title: "Cloud Documents",
    image: CloudDocuments,
    items: [
      "Seamlessly work together on documents in real-time from anywhere.",
      "Safeguard your valuable documents with state-of-the-art cloud security.",
      "Access your documents on any device, anytime, anywhere.",
      "Scale up your document storage limit to unlimited storage space.",
      "All transactional documents like invoices and receipts are stored in the cloud.",
      "Effortlessly find relevant documents for a client, supplier or employee. ",
    ],
  },
  {
    id: 8,
    title: "Communication (Email, SMS, WhatsApp)",
    image: Communication,
    items: [
      "Streamline organization messaging with our software.",
      "Connect with team and clients anywhere, on any device.",
      "Centralizes communication for easy access and response.",
      "Automated processes deliver messages promptly to the right recipients.",
      "Customize auto templates for SMS, email, and WhatsApp communication.",
      "Automate invoice and payment receipts via SMS, Email, or WhatsApp.",
      "Execute marketing campaigns using SMS and email channels.",
    ],
  },
  {
    id: 9,
    title: "CRM",
    image: CRM,
    items: [
      "Unlock the Power of Relationships with Our CRM Software!",
      "Understand Customer Insights, tailor interactions, and drive growth.",
      "Organize leads, clients, and suppliers effortlessly.",
      "Foster teamwork and share information with ease.",
      "Visualize and manage your sales process, closing deals faster.",
      "Engage clients with personalized communications and offers.",
      "Make data-driven decisions with insightful CRM analytics.",
    ],
  },
  {
    id: 10,
    title: "Sales",
    image: Presales,
    items: [
      "Visualize and manage the entire sales process effortlessly.",
      "Monitor sales performance, trends, and growth opportunities.",
      "Foster teamwork, share information, and close deals faster.",
      "Nurture relationships with personalized interactions.",
      "Accurately project future revenue for strategic planning.",
      "Access crucial sales data on-the-go for increased flexibility.",
    ],
  },
  {
    id: 11,
    title: "Service Module",
    image: Service,
    items: [
      "Revolutionize your business with our service management software!",
      "Optimize service delivery and customer interactions.",
      "Monitor service requests and progress in one platform.",
      "Assign tasks and manage workloads for better efficiency.",
      "Enhance client experience with timely, reliable services.",
      "Simplify scheduling for both clients and stylists.",
      "Monitor service requests, performance, and trends.",
    ],
  },
  {
    id: 12,
    title: "Inventory",
    image: Sales,
    items: [
      "Monitor stock levels, locations, and trends in real-time.",
      "Simplify inventory management from procurement to sales.",
      "Optimize inventory levels based on historical data and market trends.",
      "Receive notifications for low stock, expiration dates, and more.",
      "Minimize overstocking, reduce waste, and optimize resources.",
      "Seamlessly manage invenotry across multiple locations.",
      "integrate barcode scanning with our inventory system.",
    ],
  },
  {
    id: 13,
    title: "Appointments",
    image: Reminder,
    items: [
      "Simplify Appointments with Our Online Booking Software!",
      "Clients can schedule appointments effortlessly online.",
      "See open time slots, ensuring a convenient booking experience.",
      "Book on-the-go from any device, anytime.",
      "Reduce no-shows with automated appointment reminders.",
      "Stay ahead with modern online appointment technology.",
    ],
  },
  {
    id: 14,
    title: "Project Management",
    image: PostSales,
    items: [
      "Manage projects from initiation to completion seamlessly.",
      "Foster teamwork and share project information effortlessly.",
      "Monitor progress, resource allocation, and project timelines.",
      "Efficiently delegate tasks, ensuring the right people handle the right job.",
      "Optimize resource allocation, reduce administrative costs, and improve ROI",
      "Grow your projects without compromising efficiency.",
      "Stay competitive with modern project management tools.",
    ],
  },
  {
    id: 15,
    title: "Accounting",
    image: Accounting,
    items: [
      "Monitor income, expenses, and transactions effortlessly.",
      "Gain a clear view of your financial health, trends, and opportunities.",
      "Simplify record-keeping, reducing manual errors and time spent.",
      "Ensure accurate tax reporting and stay on top of financial regulations.",
      "Grow your business without compromising financial accuracy.",
      "Easily connect with other business tools for a holistic solution.",
      "Minimize accounting costs and improve overall financial efficiency.",
    ],
  },
  {
    id: 16,
    title: "Marketing",
    image: Tracking,
    items: [
      "Plan, execute, and track marketing initiatives seamlessly.",
      "Monitor performance, trends, and ROI for data-driven decisions.",
      "Foster teamwork, share information, and improve campaign effectiveness.",
      "Nurture relationships with personalized marketing interactions.",
      "Stay competitive with modern marketing management tools.",
      "Centralized hub for campaign planning, strategize, collaborate, and set goals.",
    ],
  },
  {
    id: 17,
    title: "Company website",
    image: SEODigitalMarketing,
    items: [
      "Ultimate solution to create a stunning websites and to engage your audience.",
      "Build a professional website that reflects your brand's unique identity to captivate visitors.",
      "Flawless on all devices: desktops, tablets, and smartphones for wider reach.",
      "Eye-catching Templates: Boost your creativity with ready-made templates.",
      "Our software includes built-in SEO tools to help you rank higher in search engines.",
      "Set up an online store and start selling products with ease.",
      "Gain valuable insights into web traffic and user behavior.",
      "Reliable hosting and data security to keep your website safe and running smoothly.",
      "LINK your mutiple websites into a centralized order management unit.",
    ],
  },
  {
    id: 18,
    title: "E-Commerce",
    image: OnlineShoppingCart,
    items: [
      "Create and manage your e-commerce store with ease.",
      "Monitor sales, customer behavior, and trends for informed decisions.",
      "Reach shoppers on any device with a responsive layout.",
      "Tailor the software to fit your unique e-commerce needs.",
      "Safeguard customer data and ensure secure online shopping.",
      "Boost customer engagement with promotions, discounts, and more.",
      "Manage products and stock levels for optimal sales.",
      "Build trust with your customers through secure payment gateways.",
      "Mobile Responsiveness, capture sales from every device.",
      "Invoices can be sent to your clients through SMS, email, or Whatsapp.",
      "LINK your mutiple ecommerce sites into a centralized order management unit.",
      "User-friendly product filter engine tailored to your business, enhances navigation.",
    ],
  },
  {
    id: 19,
    title: "Order Management",
    image: OrderManagement,
    items: [
      "Manage orders effortlessly from placement to fulfillment.",
      "Monitor order status, stock levels, and delivery in one platform.",
      "Tailor the software to fit your unique order management needs.",
      "Create workflow automation to mange your orders as per business needs.",
      "Real-time order tracking for customer visibility at every step.",
      "Automatic order updates and shipping notifications,",
      "Stay competitive with modern order management technology.",
    ],
  },
  {
    id: 20,
    title: "Delivery Management",
    image: FoodOrders,
    items: [
      "Optimize delivery routes for timely and cost-effective shipping.",
      "Monitor delivery status, location, and ETA in one platform.",
      "Keep customers informed with automated delivery updates.",
      "Handle growing delivery demands without compromising efficiency.",
      "Assign deliveries and manage resources for maximum efficiency.",
      "Expand delivery range from 20 to over 100 kilometers.",
      "Consolidate orders for long-distance deliveries.",
      "Capture digital signatures and photos as proof of delivery.",
    ],
  },
  {
    id: 21,
    title: "Loyalty Program",
    image: Rewards,
    items: [
      "Easily set up and manage loyalty rewards for your customers.",
      "Flexible rewards engine to define coupons, cash back and points.",
      "Monitor program effectiveness, customer engagement, and trends.",
      "Tailor the software to fit your unique loyalty program needs.",
      "Nurture relationships with personalized offers and incentives.",
      "Simplify reward redemption for a seamless customer experience.",
      "Stay competitive with modern loyalty program technology.",
      "Implement a rewarding program for customer loyalty.",
    ],
  },
  {
    id: 22,
    title: "Referrals",
    image: PaymentGateway,
    items: [
      "Effortlessly launch and manage a powerful referral program.",
      "Monitor referral activity, rewards, and program effectiveness.",
      "Empower your customers to refer friends and family.",
      "Expand your customer base through effective referrals.",
      "Simplify reward distribution for a seamless referral experience.",
      "Stay competitive with modern referral program technology.",
    ],
  },
  {
    id: 23,
    title: "Customer Survey",
    image: Accounting,
    items: [
      "Easily design and deploy customer surveys.",
      "Connect with your audience, understand their needs.",
      "Gather client feedback and make data-driven decisions.",
      "Effortless surveys, deep customer insights.",
      "Diverse customer segmentation for precise targeting.",
    ],
  },
  {
    id: 24,
    title: "E-Commerce - Lease & Rental",
    image: Appointment,
    items: [
      "Manage leases and rentals seamlessly from start to finish.",
      "Monitor property occupancy, rental income, and trends.",
      "Enhance tenant experiences with prompt communication and services.",
      "Track lease agreements, handle renewals, and never miss any deadlines.",
      "Simplify financial transactions with online payments and invoicing.",
    ],
  },
  {
    id: 25,
    title: "Membership",
    image: OrderManagement,
    items: [
      "Effortlessly handle member registrations, renewals, and more.",
      "Monitor member engagement, trends, and program effectiveness.",
      "Stay connected with personalized updates and benefits.",
      "Grow your membership base while maintaining operational efficiency.",
      "Create, promote, and manage events, empowering your members.",
      "Online payments for membership renewals and event registrations.",
    ],
  },
  {
    id: 26,
    title: "Vehicle Management",
    image: Organisation,
    items: [
      "Optimize Your Fleet with Our Vehicle Management Software!",
      "Monitor vehicles, maintenance, and performance in one platform.",
      "Stay informed on vehicle locations, fuel consumption, and more.",
      "Automate maintenance scheduling to extend vehicle lifespan.",
    ],
  },
  {
    id: 27,
    title: "External Agents",
    image: BlockChainProductAuthentication,
    items: [
      "Streamline external agent management in one platform.",
      "Simplify agent recruitment and swiftly bring them onboard.",
      "Easily assign tasks to external agents with instant notifications.",
      "Monitor agent activities, sales, and performance in real-time.",
      "Foster effective communication and cooperation with agents.",
    ],
  },
  {
    id: 28,
    title: "Asset Management",
    image: PostSales,
    items: [
      "Effortlessly manage assets from acquisition to retirement.",
      "Monitor asset status, location, and performance in a single platform.",
      "Stay proactive with automated maintenance scheduling.",
      "Streamline warranty registrations, claims, and approvals.",
      "Easily conduct audits and ensure compliance with ease.",
      "Maximize asset utilization and minimize costs.",
    ],
  },
  {
    id: 29,
    title: "Lease & Rental",
    image: Appointment,
    items: [
      "Streamline all aspects of leasing and rental activities.",
      "Effortlessly manage lease agreements, renewals, and terminations.",
      "Securely handle rent collection, deposits, and fees.",
      "Stay on top of property upkeep and tenant requests.",
      "Enhance tenant satisfaction with easy, transparent communication.",
      "Gain real-time insights into revenue, expenses, and profitability.",
      "Tailor our software to fit your unique property management needs.",
    ],
  },
  {
    id: 30,
    title: "Restaurant",
    image: FoodOrders,
    items: [
      "Streamline every aspect of your restaurant, from orders to inventory.",
      "Manage online roders, packaging and delivery.",
      "Optimize seating and reservations for a smooth guest experience.",
      "Easily update online menus, track items, and highlight specials.",
      "Efficiently manage stock levels, reduce wastage, and optimize costs.",
      "Gain insights into sales, customer preferences, and trends.",
      "Monitor orders from kitchen to delivery, ensuring accuracy and speed.",
      "Enhance customer relationships with loyalty programs and offers.",
      "Safeguard transactions with our robust payment processing.",
      "We do not shre your revenue !!!",
    ],
  },
  {
    id: 31,
    title: "Publishing",
    image: School,
    items: [
      "Optimize publishing processes from creation to distribution.",
      "Foster seamless teamwork among authors, editors, and designers.",
      "Reduce review cycles and accelerate content refinement.",
      "Effortlessly track rights, permissions, and royalties.",
      "Stay ahead of the digital transformation with our software.",
      "Opens the door for online leasing and digital library.",
    ],
  },
  {
    id: 32,
    title: "NGO",
    image: NGO,
    items: [
      "Simplify NGO management with our powerful, user-friendly platform.",
      "Easily track donations, grants, and funding sources.",
      "Efficiently manage volunteers, assignments, and events.",
      "Monitor project progress, allocate resources, and measure impact.",
      "Generate insightful reports for donors, stakeholders, and compliance.",
      "Enhance collaboration with stakeholders and beneficiaries.",
      "Maximize resources, optimize budgets, and reduce administrative overhead.",
    ],
  },
  {
    id: 33,
    title: "School",
    image: School,
    items: [
      "Empower Your School with Our Comprehensive Management Software!",
      "Simplify school operations with our all-in-one platform.",
      "Effortlessly manage student records, attendance, and more.",
      "Easily enroll students, track admissions, and manage inquiries.",
      "Optimize class schedules, teacher assignments, and resources.",
      "Monitor student progress, grades, and evaluations.",
      "Streamline fee collection, expense tracking, and budgeting.",
      "Provide tools for grading, lesson planning, and collaboration.",
      "Prepare your school for a digital, efficient, and thriving future.",
    ],
  },
  {
    id: 34,
    title: "Time Table",
    image: Reminder,
    items: [
      "Master Your Schedule with Our Time Table Software!",
      "Create and manage schedules with ease, no more manual hassles.",
      "Maximize efficiency by smartly allocating time and resources.",
      "Eliminate scheduling conflicts and time wastage.",
    ],
  },
  {
    id: 35,
    title: "Student LMS",
    image: BlockChainContracts,
    items: [
      "Empower Learning with Our Student LMS Software!",
      "Access courses, assignments, and resources in one place.",
      "Tailor education to individual needs and pace.",
      "Monitor performance and receive feedback for improvement.",
      "Tools for effective teaching, grading, and student management.",
      "Prepare for tomorrow with modern, digital learning solutions.",
    ],
  },
  {
    id: 36,
    title: "Healthcare",
    image: PaymentGateway,
    items: [
      "Optimize clinic management with our user-friendly platform.",
      "Enhance patient experience and engagement.",
      "Efficiently manage appointments, reducing wait times.",
      "Safeguard patient information with robust security measures.",
      "Streamline billing, insurance claims, and revenue tracking.",
      "Stay compliant with industry regulations and standards.",
    ],
  },
  {
    id: 37,
    title: "Blockchain Contracts",
    image: BlockChainContracts,
    items: [
      "Transform Your Contracts with Blockchain Technology!",
      "Ensure contract integrity with immutable blockchain records.",
      "Enhance trust and transparency in contract dealings.",
      "Cut down administrative costs with automated contract management.",
      "Embrace the power of blockchain for contract innovation.",
    ],
  },
  {
    id: 38,
    title: "Blockchain Product Authentication",
    image: BlockChainProductAuthentication,
    items: [
      "Boost Trust with Our Blockchain Product Authentication Software!",
      "Ensure the authenticity of your products with unalterable blockchain records.",
      "Instantly verify product legitimacy, enhancing customer trust and loyalty.",
      "Establish supply chain transparency from manufacturer to consumer.",
      "Combat counterfeit products, protecting your reputation and revenue",
    ],
  },
  {
    id: 39,
    title: "Recruitment",
    image: SupportTicket,
    items: [
      "Streamline your hiring process and discover top-notch talent effortlessly.",
      "Attract, assess, and hire the best candidates for your organization.",
      "Manage job openings and efficiently manage candidate applications.",
      "Efficient applicant tracking with advanced screening.",
      "Collaboration among your hiring team, ensuring a seamless hiring experience.",
      "Simplifiy interview scheduling and send automated reminders.",
    ],
  },
  {
    id: 40,
    title: "Event Management",
    image: NGO,
    items: [
      "Streamline your event planning and execution.",
      "Comprehensive event organization, covering logistics to guest management.",
      "Manage event timelines, tasks, and budgets effortlessly.",
      "Effortless attendee sign-ups, automated updates for guests' convenience.",
      "Manage venue bookings, equipment rentals, and resource allocation.",
    ],
  },
  {
    id: 41,
    title: "Resource Management",
    image: Tracking,
    items: [
      "Optimize Resource Management for Enhanced Efficiency!",
      "Smartly allocate resources, matching tasks with skills.",
      "Bird's-eye view of your resource utilization.",
      "Maximize ROI by identifying underutilized resources.",
      "Tailor to Your Specific Business Needs and Processes.",
    ],
  },
];
