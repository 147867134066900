const firebaseConfig = {
  apiKey: "AIzaSyAPvlmcqdsR2Z7Jg-uQWJySmVXFOXHIuKY",
    authDomain: "tansy-cloud-static-website.firebaseapp.com",
    projectId: "tansy-cloud-static-website",
    storageBucket: "tansy-cloud-static-website.appspot.com",
    messagingSenderId: "307867044735",
    appId: "1:307867044735:web:dbd08565b2dc1ae8c9284a",
    measurementId: "G-L6PGBTN1V6",
};

export default firebaseConfig;
