import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Box, CardMedia, Container, Typography } from "@mui/material";
import { sectionsData } from "../../data/Solutions";
import photo from "../../images/TansySubtract.svg";
import Xarrow, { Xwrapper } from "react-xarrows";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";

const containerStyle = {
  padding: "16px",

};

const boxStyle = {
  height: "auto",
  width: "290px",
  margin: "auto",
};

export default function Features() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("1100"));
  const sectionBoxes = sectionsData.map((section, index) => (
    <Box key={index} style={boxStyle}>
      <Lopping
        frameIcon={section.frameIcon}
        title={section.title}
        items={section.items}
        id={section.id}
      />
    </Box>
  ));

  const ViewBoxes = sectionsData.map((section, index) => (
    <Box key={index} style={styles[`box${index}`]}>
      <Lopping
        frameIcon={section.frameIcon}
        title={section.title}
        items={section.items}
        id={section.id}
        key={section.id}
      />
    </Box>
  ));
  const [lines] = useState({
    dashness: { animation: 1 },
    color: "green",
    path: "grid",
    endAnchor: "auto",
    animateDrawing: "5",
    headColor: "rgb(37 99 235)",
    lineColor: "green",
    strokeWidth: "2",
    headSize: "5",
  });

  return (
    <div>
      {isMatch ? (
        <Box sx={styles.container}>
          {ViewBoxes}
          <div
            id="7"
            style={{
              border: "solid",
              width: "1px",
              height: "1px",
              position: "relative",
              left: "550px",
              top: "110px",
            }}
          />
          <Box sx={{ width: "130px", height: "100px", margin: "auto" }}>
            <CardMedia
              id="6"
              className="card-img "
              component="img"
              image={photo}
              alt="Img"
              loading="lazy"
              position="relative"
            />
          </Box>
          <Xwrapper>
            {[3, 4, 5].map((end) => (
              <Xarrow {...lines} key={end} start={"6"} end={end.toString()} />
            ))}
            <Xarrow {...lines} start={"6"} end={"7"} />
            <Xarrow {...lines} start={"7"} end={"1"} />
            <Xarrow {...lines} start={"7"} end={"2"} />
          </Xwrapper>
        </Box>
      ) : (
        <Box sx={{ width: "100%", height: "auto" }}>
          <Container fluid style={containerStyle}>
            <Box sx={{ minHeight: "900px", width: "Auto" }}>
              <Box
                sx={{
                  width: "130px",
                  height: "100px",
                  margin: "auto",
                  marginBottom: "30px",
                }}
              >
                <CardMedia
                  id="6"
                  className="card-img"
                  component="img"
                  image={photo}
                  alt="Img"
                  loading="lazy"
                  position="relative"
                />
              </Box>
              <Row className="g-4">
                <Col lg={8} md={12} sx={{ ...boxStyle, width: "100%" }}>
                  {sectionBoxes[0]}
                </Col>
                <Col lg={4} md={6} sx={{ ...boxStyle, width: "100%" }}>
                  {sectionBoxes[1]}
                </Col>

                <Col lg={4} md={6} sx={{ ...boxStyle, width: "100%" }}>
                  {sectionBoxes[2]}
                </Col>

                <Col lg={4} md={6} sx={{ ...boxStyle, width: "100%" }}>
                  {sectionBoxes[3]}
                </Col>
                <Col lg={4} md={6} sx={{ ...boxStyle, width: "100%" }}>
                  {sectionBoxes[4]}
                </Col>
              </Row>
            </Box>
          </Container>
        </Box>
      )}
    </div>
  );
}

const Lopping = ({ id, frameIcon, title, items }) => {
  return (
    <Box className="position-relative" style={{ margin: "5px" }}>
      <CardMedia
        component="img"
        image={frameIcon}
        alt={title}
        id={id}
        loading="lazy"
        style={{ width: "36px", height: "36px" }}
      />

      <Box padding={1} width="100%" height="auto">
        <Typography
          variant="h6"
          textAlign="left"
          component="h1"
          paddingBottom={1}
          fontWeight="bold"
          fontSize="18px"
          lineHeight="34px"
        >
          {title}
        </Typography>
        <Typography gutterBottom textAlign="left" component="ul">
          {items.map((item, i) => (
            <Typography
              key={i}
              component="li"
              variant="body2"
              fontWeight="350px"
              fontSize="15px"
              lineHeight="150%"
            >
              {item}
            </Typography>
          ))}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    position: "relative",
    width: "1100px",
    height: "900px",
    margin: "auto",
    marginTop: "16px",
    display: "fixed",
  },
  box0: {
    position: "absolute",
    width: "310px",
    height: "auto",
    top: "70px",
    marginLeft: "80px",
    display: "flex",
  },
  box1: {
    position: "absolute",
    width: "310px",
    height: "294px",
    right: "60px",
    margineTop: "auto",
    display: "flex",
  },
  box2: {
    position: "absolute",
    width: "310px",
    height: "294px",
    marginLeft: "auto",
    bottom: "100px",
    display: "flex",
  },
  box3: {
    position: "absolute",
    width: "310px",
    height: "294px",
    left: "420px",
    alignItems: "center",
    bottom: "-40px",
    display: "flex",
  },
  box4: {
    position: "absolute",
    width: "310px",
    height: "294px",
    right: "0px",
    top: "440px",
    display: "flex",
  },
};
