import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Button, Paper, Typography } from "@mui/material";
import { WeSupport } from "../Home";
import { BusinessData } from "../../data/Home";
import WhyChoose from "../../components/WhyChoose/WhyChoose";
import Schedule from "../../components/Schedule/Schedule";
import Features from "./Features";

export default function index() {
  return (
    <React.Fragment>
      <Outlet />
    </React.Fragment>
  );
}

export function Header() {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = (event) => {
    setIsHovering(true);
    event.target.style.transform = "scale(1.05)";
  };

  const handleMouseLeave = (event) => {
    setIsHovering(false);
    event.target.style.transform = "none";
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "auto",
          marginBottom: "120px",
          margin: "auto",
        }}
      >
        <Box
          sx={{ maxWidth: "700px", marginTop: "70px", marginBottom: "50px" }}
        >
          <Typography
            fontWeight="bold"
            fontSize={{ xs: "30px", md: "42px" }}
            textAlign="center"
            letterSpacing="-0.03em"
            gutterBottom
            color="#011037"
          >
            Tansy Cloud Solutions
          </Typography>
          <Typography
            padding="0px 10px"
            variant="body1"
            gutterBottom
            color="#64607D"
          >
            Lorem ipsum dolor sit amet consectetur. Massa ultrices non mauris
            vestibulum vulputate bibendum. Vulputate sodales orci blandit risus
            et cursus. Volutpat diam justo.
          </Typography>
          <Box padding={4}>
            <Button
              variant="contained"
              style={{
                padding: "13px 36px",
                borderRadius: "15px",
                textTransform: "capitalize",
                transition: "all 0.3s ease-in-out",
                transform: isHovering ? "scale(1.05)" : "none",
              }}
              className="style"
              onClick={() => navigate("/contact-us")}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Request a Demo
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function ProductFeatures() {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = (event) => {
    setIsHovering(true);
    event.target.style.transform = "scale(1.05)";
  };

  const handleMouseLeave = (event) => {
    setIsHovering(false);
    event.target.style.transform = "none";
  };
  return (
    <React.Fragment>
      <Products />
      <Features />
      <Box padding={4} marginTop="100px">
        <Button
          variant="outlined"
          style={{
            padding: "15px 40px",
            borderRadius: "15px",
            fontWeight: "bold",
            textTransform: "capitalize",
            transition: "all 0.3s ease-in-out",
            transform: isHovering ? "scale(1.05)" : "none",
          }}
          className="style"
          onClick={() => navigate("")}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          See all Features
        </Button>
      </Box>
    </React.Fragment>
  );
}

export function SolutionsPage() {
  React.useEffect(() => {
    document.title = "Tansy Cloud | Solutions";
  }, []);

  return (
    <Box margin="10px">
      <Paper elevation={3} padding="10px">
        <Box>
          <Header />
          <WeSupport data={BusinessData} />
          <ProductFeatures />
          <Box
            sx={{
              p: 2,
              margin: "auto",
              maxWidth: 950,
              flexGrow: 1,
              backgroundColor: "#fff",
            }}
          >
            <WhyChoose />
          </Box>
          <Schedule />
          <Box paddingBottom="10px" />
        </Box>
      </Paper>
    </Box>
  );
}

const productTitle = {
  fontWeight: "bold",
  fontSize: { xs: "30px", md: "42px" },
  textAlign: "center",
  letterSpacing: "-0.03em",
  color: "#011037",
  gutterBottom: true,
};

const productDescription = {
  variant: "body1",
  gutterBottom: true,
  color: "#64607D",
  fontSize: { xs: "17px", md: "19px" },
};

const Products = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "auto",
        marginBottom: "120px",
        margin: "auto",
      }}
    >
      <Box sx={{ maxWidth: "850px", marginTop: "2px", marginBottom: "50px" }}>
        <Typography {...productTitle}>Tansy Cloud Product Features</Typography>
        <Typography {...productDescription}>
          Lorem ipsum dolor sit amet consectetur. Massa ultrices non mauris
          vestibulum vulputate bibendum. Vulputate sodales orci blandit risus et
          cursus. Volutpat diam justo.
        </Typography>
      </Box>
    </Box>
  );
};
