import Reminder from "../images/Reminder.svg";
import Communication from "../images/Communication.svg";
import CRM from "../images/CRM.svg";
import CloudDocuments from "../images/CloudDocuments.svg";
import AdminSettings from "../images/AdminSettings.svg";
import Organisation from "../images/Organisation.svg";
import SEODigitalMarketing from "../images/SEODigitalMarketing.svg";
import OnlineShoppingCart from "../images/OnlineShoppingCart.svg";
import OrderManagement from "../images/OrderManagement.svg";
import Inventory from "../images/Inventory.svg";
import Accounting from "../images/Accounting.svg";
import Presales from "../images/Presales.svg";
import Sales from "../images/Sales.jpg";
import PostSales from "../images/PostSales.svg";
import SupportTicket from "../images/SupportTicket.svg";
import NGO from "../images/NGO.svg";
import Tracking from "../images/Tracking.jpg";
import School from "../images/School.svg";
import BlockChainContracts from "../images/BlockChainContracts.svg";

export const Data = {
  Title: "Software for NGOs",
  subTitle:
    "Powering positive change with our NGO software. Simplify operations, engage supporters, track impact, and amplify your mission's reach. Join us!",
  Btn: [
    { label: "Back to Solutions", nav: "/solutions/allservices" },
    { label: "Schedule a Demo", nav: "/contact-us" },
  ],
};

export const sectionsData = [
  {
    id: 1,
    title: "Admin & Security",
    image: AdminSettings,
    items: [
      "Total control ensures authorized access to sensitive information.",
      "Manage screen permissions through security groups for system users.",
      "Monitor employee productivity and identifying any unusual behavior.",
      "Values for system-wide drop downs can be added, deleted, or updated.",
      "You can add custom fields to selected screens, such as leads and clients.",
      "Automated backups, ensuring that your critical business data is safe.",
    ],
  },
  {
    id: 2,
    title: "Reports and Dashboards",
    image: PostSales,
    items: [
      "Derive Informed decisions from our reports and dashboards.",
      "Inventory: Stay on top of stock levels and orders.",
      "Sales: Track sales performance, products, and team.",
      "Finance: Monitor revenue, expenses, and ratios.",
      "Customer: Improve retention with behavior insights.",
      "CRM: Organize, analyze, and optimize customer interactions.",
      "Social Media: Gauge engagement and campaign impact.",
      "Web: Understand website traffic and user behavior.",
      "Marketing: Analyze campaigns, conversion rates, and ROI.",
      "Project Management: Monitor progress, resources, and budget.",
    ],
  },
  {
    id: 3,
    title: "Data Science (Predective Analytics)",
    image: Inventory,
    items: [
      "Data science enables precise targeting, resource allocation, and insights.",
      "Segment audience by behavior, enabling precise targeting with data tools.",
      "Churn Prediction: Proactively retain clients with data-driven strategies.",
      "Ensure optimal inventory levels with precise data analysis.",
    ],
  },
  {
    id: 4,
    title: "Organisation",
    image: Organisation,
    items: [
      "Streamline operations and empower your team to achieve more.",
      "Centralize data, automate, and enhance team collaboration.",
      "Efficiently manage client, lead, and supplier details, workflows, and more.",
      "Monitor expenses incurred by departments, employees, clients and vehicles.",
      "Enable the Google Map tracking feature for field agents.",
      "Employees, leads, clients, suppliers, and equipments seamlessly interconnect.",
    ],
  },
  {
    id: 5,
    title: "HR",
    image: SupportTicket,
    items: [
      "Streamline operations and empower your team to achieve more.",
      "Centralize data, automate, and enhance team collaboration.",
      "Efficiently manage client, lead, and supplier details, workflows, and more.",
      "Monitor expenses incurred by departments, employees, clients and vehicles.",
      "Enable the Google Map tracking feature for field agents.",
      "Employees, leads, clients, suppliers, and equipments seamlessly interconnect.",
    ],
  },
  {
    id: 6,
    title: "Workflow (automation)",
    image: Tracking,
    items: [
      "Streamline tasks, reduce manual effort, and save time.",
      "Tailor automation to your unique business processes.",
      "Minimize mistakes and improve accuracy with automated processes.",
      "Track progress, identify bottlenecks, and make informed decisions.",
      "Ocus on strategic initiatives while repetitive tasks run automatically.",
      "Stay competitive with advanced workflow automation.",
      "Maximize resources, cut down on administrative costs, and boost ROI.",
      "Optimize your workflow and experience the benefits today!",
    ],
  },
  {
    id: 7,
    title: "Cloud Documents",
    image: CloudDocuments,
    items: [
      "Seamlessly work together on documents in real-time from anywhere.",
      "Safeguard your valuable documents with state-of-the-art cloud security.",
      "Access your documents on any device, anytime, anywhere.",
      "Scale up your document storage limit to unlimited storage space.",
      "All transactional documents like invoices and receipts are stored in the cloud.",
      "Effortlessly find relevant documents for a client, supplier or employee. ",
    ],
  },
  {
    id: 8,
    title: "Communication (Email, SMS, WhatsApp)",
    image: Communication,
    items: [
      "Streamline organization messaging with our software.",
      "Connect with team and clients anywhere, on any device.",
      "Centralizes communication for easy access and response.",
      "Automated processes deliver messages promptly to the right recipients.",
      "Customize auto templates for SMS, email, and WhatsApp communication.",
      "Automate invoice and payment receipts via SMS, Email, or WhatsApp.",
      "Execute marketing campaigns using SMS and email channels.",
    ],
  },
  {
    id: 9,
    title: "CRM",
    image: CRM,
    items: [
      "Unlock the Power of Relationships with Our CRM Software!",
      "Understand Customer Insights, tailor interactions, and drive growth.",
      "Organize leads, clients, and suppliers effortlessly.",
      "Foster teamwork and share information with ease.",
      "Visualize and manage your sales process, closing deals faster.",
      "Engage clients with personalized communications and offers.",
      "Make data-driven decisions with insightful CRM analytics.",
    ],
  },
  {
    id: 10,
    title: "Sales",
    image: Presales,
    items: [
      "Visualize and manage the entire sales process effortlessly.",
      "Monitor sales performance, trends, and growth opportunities.",
      "Foster teamwork, share information, and close deals faster.",
      "Nurture relationships with personalized interactions.",
      "Accurately project future revenue for strategic planning.",
      "Access crucial sales data on-the-go for increased flexibility.",
    ],
  },

  {
    id: 11,
    title: "Inventory",
    image: Sales,
    items: [
      "Monitor stock levels, locations, and trends in real-time.",
      "Simplify inventory management from procurement to sales.",
      "Optimize inventory levels based on historical data and market trends.",
      "Receive notifications for low stock, expiration dates, and more.",
      "Minimize overstocking, reduce waste, and optimize resources.",
      "Seamlessly manage invenotry across multiple locations.",
      "integrate barcode scanning with our inventory system.",
    ],
  },
  {
    id: 12,
    title: "Appointments",
    image: Reminder,
    items: [
      "Simplify Appointments with Our Online Booking Software!",
      "Clients can schedule appointments effortlessly online.",
      "See open time slots, ensuring a convenient booking experience.",
      "Book on-the-go from any device, anytime.",
      "Reduce no-shows with automated appointment reminders.",
      "Stay ahead with modern online appointment technology.",
    ],
  },
  {
    id: 13,
    title: "Project Management",
    image: PostSales,
    items: [
      "Manage projects from initiation to completion seamlessly.",
      "Foster teamwork and share project information effortlessly.",
      "Monitor progress, resource allocation, and project timelines.",
      "Efficiently delegate tasks, ensuring the right people handle the right job.",
      "Optimize resource allocation, reduce administrative costs, and improve ROI",
      "Grow your projects without compromising efficiency.",
      "Stay competitive with modern project management tools.",
    ],
  },
  {
    id: 14,
    title: "Accounting",
    image: Accounting,
    items: [
      "Monitor income, expenses, and transactions effortlessly.",
      "Gain a clear view of your financial health, trends, and opportunities.",
      "Simplify record-keeping, reducing manual errors and time spent.",
      "Ensure accurate tax reporting and stay on top of financial regulations.",
      "Grow your business without compromising financial accuracy.",
      "Easily connect with other business tools for a holistic solution.",
      "Minimize accounting costs and improve overall financial efficiency.",
    ],
  },

  {
    id: 15,
    title: "Company website",
    image: SEODigitalMarketing,
    items: [
      "Ultimate solution to create a stunning websites and to engage your audience.",
      "Build a professional website that reflects your brand's unique identity to captivate visitors.",
      "Flawless on all devices: desktops, tablets, and smartphones for wider reach.",
      "Eye-catching Templates: Boost your creativity with ready-made templates.",
      "Our software includes built-in SEO tools to help you rank higher in search engines.",
      "Set up an online store and start selling products with ease.",
      "Gain valuable insights into web traffic and user behavior.",
      "Reliable hosting and data security to keep your website safe and running smoothly.",
      "LINK your mutiple websites into a centralized order management unit.",
    ],
  },
  {
    id: 16,
    title: "E-Commerce",
    image: OnlineShoppingCart,
    items: [
      "Create and manage your e-commerce store with ease.",
      "Monitor sales, customer behavior, and trends for informed decisions.",
      "Reach shoppers on any device with a responsive layout.",
      "Tailor the software to fit your unique e-commerce needs.",
      "Safeguard customer data and ensure secure online shopping.",
      "Boost customer engagement with promotions, discounts, and more.",
      "Manage products and stock levels for optimal sales.",
      "Build trust with your customers through secure payment gateways.",
      "Mobile Responsiveness, capture sales from every device.",
      "Invoices can be sent to your clients through SMS, email, or Whatsapp.",
      "LINK your mutiple ecommerce sites into a centralized order management unit.",
      "User-friendly product filter engine tailored to your business, enhances navigation.",
    ],
  },

  {
    id: 17,
    title: "Customer Survey",
    image: Accounting,
    items: [
      "Easily design and deploy customer surveys.",
      "Connect with your audience, understand their needs.",
      "Gather client feedback and make data-driven decisions.",
      "Effortless surveys, deep customer insights.",
      "Diverse customer segmentation for precise targeting.",
    ],
  },

  {
    id: 18,
    title: "Membership",
    image: OrderManagement,
    items: [
      "Effortlessly handle member registrations, renewals, and more.",
      "Monitor member engagement, trends, and program effectiveness.",
      "Stay connected with personalized updates and benefits.",
      "Grow your membership base while maintaining operational efficiency.",
      "Create, promote, and manage events, empowering your members.",
      "Online payments for membership renewals and event registrations.",
    ],
  },
  {
    id: 19,
    title: "Vehicle Management",
    image: Organisation,
    items: [
      "Optimize Your Fleet with Our Vehicle Management Software!",
      "Monitor vehicles, maintenance, and performance in one platform.",
      "Stay informed on vehicle locations, fuel consumption, and more.",
      "Automate maintenance scheduling to extend vehicle lifespan.",
    ],
  },

  {
    id: 20,
    title: "Asset Management",
    image: PostSales,
    items: [
      "Effortlessly manage assets from acquisition to retirement.",
      "Monitor asset status, location, and performance in a single platform.",
      "Stay proactive with automated maintenance scheduling.",
      "Streamline warranty registrations, claims, and approvals.",
      "Easily conduct audits and ensure compliance with ease.",
      "Maximize asset utilization and minimize costs.",
    ],
  },

  {
    id: 21,
    title: "Publishing",
    image: School,
    items: [
      "Optimize publishing processes from creation to distribution.",
      "Foster seamless teamwork among authors, editors, and designers.",
      "Reduce review cycles and accelerate content refinement.",
      "Effortlessly track rights, permissions, and royalties.",
      "Stay ahead of the digital transformation with our software.",
      "Opens the door for online leasing and digital library.",
    ],
  },
  {
    id: 22,
    title: "NGO",
    image: NGO,
    items: [
      "Simplify NGO management with our powerful, user-friendly platform.",
      "Easily track donations, grants, and funding sources.",
      "Efficiently manage volunteers, assignments, and events.",
      "Monitor project progress, allocate resources, and measure impact.",
      "Generate insightful reports for donors, stakeholders, and compliance.",
      "Enhance collaboration with stakeholders and beneficiaries.",
      "Maximize resources, optimize budgets, and reduce administrative overhead.",
    ],
  },
  {
    id: 23,
    title: "School",
    image: School,
    items: [
      "Empower Your School with Our Comprehensive Management Software!",
      "Simplify school operations with our all-in-one platform.",
      "Effortlessly manage student records, attendance, and more.",
      "Easily enroll students, track admissions, and manage inquiries.",
      "Optimize class schedules, teacher assignments, and resources.",
      "Monitor student progress, grades, and evaluations.",
      "Streamline fee collection, expense tracking, and budgeting.",
      "Provide tools for grading, lesson planning, and collaboration.",
      "Prepare your school for a digital, efficient, and thriving future.",
    ],
  },
  {
    id: 24,
    title: "Time Table",
    image: Reminder,
    items: [
      "Master Your Schedule with Our Time Table Software!",
      "Create and manage schedules with ease, no more manual hassles.",
      "Maximize efficiency by smartly allocating time and resources.",
      "Eliminate scheduling conflicts and time wastage.",
    ],
  },
  {
    id: 25,
    title: "Student LMS",
    image: BlockChainContracts,
    items: [
      "Empower Learning with Our Student LMS Software!",
      "Access courses, assignments, and resources in one place.",
      "Tailor education to individual needs and pace.",
      "Monitor performance and receive feedback for improvement.",
      "Tools for effective teaching, grading, and student management.",
      "Prepare for tomorrow with modern, digital learning solutions.",
    ],
  },
];
