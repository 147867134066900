import Card from "react-bootstrap/Card";
import MailIcon from "@mui/icons-material/Mail";
import { Box, Button, Container, List, ListItem } from "@mui/material";
import { ListItemText, Paper, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import twitterCircleFilled from "@iconify/icons-ant-design/twitter-circle-filled";
import baselineFacebook from "@iconify/icons-ic/baseline-facebook";
import linkedinWithCircle from "@iconify/icons-entypo-social/linkedin-with-circle";
import socialInstagramCircular from "@iconify/icons-typcn/social-instagram-circular";
import BaselineTwitter from "../../images/BaselineTwitter.png";
import Baselinefacebook from "../../images/Baselinefacebook.png";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import firebaseConfig from "../Secure/Config";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, push } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

export default function index() {

  return (
    <React.Fragment>
      <Banner />
      <ContactUs />
    </React.Fragment>
  );
}

function NavigateFacebook() {
  // window.open("https://www.facebook.com/login/", "_blank");
}
function NavigateTwitter() {
  // window.open("https://twitter.com/login/", "_blank");
}
function NavigateInstagram() {
  // window.open("https://www.instagram.com/accounts/login/", "_blank");
}
function NavigateLinkedIn() {
  window.open("https://www.linkedin.com/company/tansy-cloud/", "_blank");
}

const icons = [
  {
    icon: baselineFacebook,
    width: 29,
    height: 29,
    cursor: "pointer",
    nav: NavigateFacebook,
  },
  {
    icon: socialInstagramCircular,
    width: 31,
    height: 31,
    cursor: "pointer",
    nav: NavigateInstagram,
  },
  {
    icon: twitterCircleFilled,
    width: 28,
    height: 29,
    cursor: "pointer",
    nav: NavigateTwitter,
  },
  {
    icon: linkedinWithCircle,
    width: 25,
    height: 28,
    cursor: "pointer",
    nav: NavigateLinkedIn,
  },
];
function Banner() {
  return (
    <>
      <Box
        className=" text-white"
        position="relative"
        style={{
          top: "-1px",
          width: "auto",
          height: "400px",
          borderRadius: "0px",
          background: "linear-gradient(180deg, #3062BE 0%, #328FD6 100%)",
        }}
      >
        <Card.ImgOverlay
          style={{
            textAlign: "center",
          }}
        >
          <Typography
            fontWeight={600}
            marginTop={8}
            style={{
              fontSize: "42px",
            }}
          >
            Get in Touch
          </Typography>
          <MailIcon
            style={{ width: "80px", height: "80px", marginTop: "24px" }}
          />
          <Card.Text
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              letterSpacing: "0.01em",
            }}
          >
            ceo@tansycloud.com
          </Card.Text>
          <Card.Text
            style={{
              lineHeight: "20px",
              paddingBottom: "10px",
              fontSize: "19px",
              opacity: 0.8,
            }}
          >
            Write to us for Alliances and Partnership requests
          </Card.Text>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            sx={{ color: "#FFFFFF" }}
          >
            {icons.map((item, index) => (
              <Icon
                key={index}
                icon={item.icon}
                width={item.width}
                height={item.height}
                style={{ cursor: item.cursor }}
                onClick={item.nav}
              />
            ))}
          </Stack>{" "}
        </Card.ImgOverlay>
      </Box>

      <Box style={{ zIndex: 2 }}>
        <Card.Img
          src={BaselineTwitter}
          style={{
            width: "170px",
            height: "195px",
            position: "absolute",
            left: "0px",
            top: "100px",
          }}
          alt="Card image"
        />
      </Box>
      <Box>
        <Card.Img
          src={Baselinefacebook}
          style={{
            width: "175px",
            height: "175px",
            position: "absolute",
            right: "0px",
            top: "370px",
          }}
          alt="Card image"
        />
      </Box>
    </>
  );
}

function ContactUs() {
  return (
    <Box margin="10px">
      <Paper elevation={3} padding="10px">
        <Typography
          variant="h4"
          fontWeight="bold"
          paddingTop={10}
          marginBottom={10}
          gutterBottom
          color="#011037"
        >
          Contact Us
        </Typography>
        <FormTextFields />
      </Paper>
    </Box>
  );
}

function SubmittedData({ formData, onClose }) {
  return (
    <Box maxWidth="900px" margin="auto" paddingBottom={15}>
      <Box textAlign="left">
        <Typography variant="h5" gutterBottom color="green" fontWeight="bold">
          DATA SUBMITTED
        </Typography>
        <List>
          {Object.entries(formData).map(([key, value]) => (
            <ListItem key={key}>
              <ListItemText color="#263238">
                <div style={{ display: "flex", margin: "5px" }}>
                  <span
                    style={{ marginRight: "5px", fontWeight: "bold" }}
                  >{`${key}: `}</span>
                  <span class="w-70" style={{ color: " #757095" }}>
                    {value}
                  </span>
                </div>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        <Box marginTop={8} textAlign="right">
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function FormTextFields() {
  const navigate = useNavigate();
  const [formData, setFormData] = React.useState({
    fullName: "",
    email: "",
    country: "",
    mobileNumber: "",
    message: ``,
    contactType: "Demo Request",
  });

  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const [phone, setPhone] = React.useState("+91");

  const handleChange = (newPhone) => {
    setPhone(newPhone);
    matchIsValidTel(newPhone);
    setFormData({ ...formData, mobileNumber: newPhone });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);

    const errors = {
      fullName: !isValidName(formData.fullName),
      email: !isValidEmail(formData.email),
      message: !isValidMessage(formData.message),
    };

    if (!Object.values(errors).some((error) => error)) {
      const firebaseApp = initializeApp(firebaseConfig);
      const analytics = getAnalytics(firebaseApp);
      const database = getDatabase(firebaseApp);
      const { fullName, email, country, mobileNumber, message, contactType } =
        formData;

      const eventEventData = {
        eventName: "FormSubmission",
        formData: {
          fullName,
          email,
          country,
          mobileNumber,
          message,
          contactType,
        },
        timestamp: new Date().toISOString(),
        Received: false,
      };
      const messageRef = ref(database, "Contact form");
      try {
        await push(messageRef, eventEventData);
        analytics.logEvent("submitForm", eventEventData);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleClose = () => {
    setIsSubmitted(false);
    setFormData({
      fullName: "",
      email: "",
      country: "",
      mobileNumber: "",
      message: ``,
      contactType: "Demo Request",
    });
    navigate("/");
  };

  const firstTextFieldRef = React.useRef(null);

  React.useEffect(() => {
     document.title = "Tansy Cloud | Contact";
    if (firstTextFieldRef.current) {
      firstTextFieldRef.current.focus();
    }
  }, []);

  const radioButtons = [
    { label: "Demo Request", value: "Demo Request" },
    { label: "Pricing Query", value: "Pricing Query" },
    { label: "General Query", value: "General Query" },
  ];

  const styles = {
    padding: "9px 70px",
    borderRadius: "20px",
    backgroundColor: " #328FD6",
    color: "#fff",
    textTransform: "capitalize",
    boxShadow: `
    0px 100px 80px rgba(56, 56, 56, 0.1),
    0px 64.8148px 46.8519px rgba(56, 56, 56, 0.0759259),
    0px 38.5185px 25.4815px rgba(56, 56, 56, 0.0607407),
    0px 20px 13px rgba(56, 56, 56, 0.05),
    0px 8.14815px 6.51852px rgba(56, 56, 56, 0.0392593),
    0px 1.85185px 3.14815px rgba(56, 56, 56, 0.0240741)
  `,
  };

  if (isSubmitted) {
    return <SubmittedData formData={formData} onClose={handleClose} />;
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isValidName(name) {
    const words = name.split(" ");
    return words.every((word) => word.length >= 2 && /^[A-Za-z]+$/.test(word));
  }

  function isValidPhoneNumber(mobileNumber) {
    return mobileNumber.length > 10;
  }

  function isValidMessage(message) {
    const trimmedMessage = message.trim();
    return trimmedMessage !== "" && trimmedMessage.length >= 2;
  }

  return (
    <Box maxWidth="900px" margin="auto" paddingBottom={15}>
      <Container
        component="form"
        onSubmit={handleSubmit}
        sx={{
          "& .MuiTextField-root": {
            m: 1,
            width: { sm: "35ch", md: "45ch" },
          },
        }}
        validate="true"
        autoComplete="off"
      >
        <FormControl>
          <div style={{ display: "flex", margin: "10px" }}>
            <TextField
              label="Full Name"
              name="fullName"
              type="text"
              value={formData.fullName}
              inputRef={firstTextFieldRef}
              required
              sx={{ flexGrow: 1, mr: 1 }}
              error={
                !isValidName(formData.fullName) && formData.fullName !== ""
              }
              helperText={
                !isValidName(formData.fullName) && formData.fullName !== ""
                  ? "Invalid name"
                  : ""
              }
              onChange={handleInputChange}
            />
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              error={!isValidEmail(formData.email) && formData.email !== ""}
              helperText={
                !isValidEmail(formData.email) && formData.email !== ""
                  ? "Invalid email"
                  : ""
              }
              required
              sx={{ flexGrow: 1 }}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "flex", margin: "10px" }}>
            <TextField
              label="Country"
              name="country"
              value={formData.country}
              sx={{ flexGrow: 1, mr: 1 }}
              onChange={handleInputChange}
            />
            <MuiTelInput
              label="Mobile number"
              name="mobileNumber"
              sx={{ flexGrow: 1 }}
              error={
                !isValidPhoneNumber(formData.mobileNumber) &&
                formData.mobileNumber !== ""
              }
              helperText={
                !isValidPhoneNumber(formData.mobileNumber) &&
                formData.mobileNumber !== ""
                  ? "Please enter a valid 10-digit Mobile Number"
                  : ""
              }
              value={phone}
              onChange={handleChange}
            />
          </div>
          <div style={{ display: "flex", margin: "10px" }}>
            <TextField
              label="Message"
              name="message"
              type="text"
              value={formData.message}
              multiline
              rows={5}
              required
              sx={{ flexGrow: 1 }}
              error={
                !isValidMessage(formData.message) && formData.message !== ""
              }
              helperText={
                !isValidMessage(formData.message) && formData.message !== ""
                  ? "Please provide a valid message"
                  : ""
              }
              onChange={handleInputChange}
            />
          </div>
        </FormControl>

        <Box textAlign="left">
          <FormControl>
            <Box paddingLeft={3}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                <Typography padding="10px 0px" color="#263238">
                  Contact Type
                </Typography>
              </FormLabel>{" "}
            </Box>
            <Box paddingLeft={3}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="contactType"
                value={formData.contactType}
                onChange={handleInputChange}
              >
                {radioButtons.map((button) => (
                  <FormControlLabel
                    key={button.value}
                    value={button.value}
                    control={<Radio />}
                    label={button.label}
                    color="#263238"
                  />
                ))}
              </RadioGroup>
            </Box>
          </FormControl>
        </Box>
        <Box marginTop={8}>
          <Button
            style={styles}
            onMouseEnter={() => {
              const button = document.querySelector("#submit");
              button.style.transform = "scale(1.1)";
            }}
            onMouseLeave={() => {
              const button = document.querySelector("#submit");
              button.style.transform = "scale(1)";
            }}
            type="submit"
            id="submit"
          >
            Send
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
