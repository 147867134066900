import Union from "../../images/Union.svg";
import InputSubscription from "../../components/Subscription/InputSubscription";
import { Box, Typography } from "@mui/material";

const Schedule = () => {
  return (
    <Box sx={{ paddingBottom: "120px", paddingTop: "70px" }}>
      <Box
        sx={{
          background: `linear-gradient(180deg, #3062BE 0%, #328FD6 100%)`,
          borderRadius: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "1100px",
          margin: "auto",
          position: "relative",
          height: "250px",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            maxWidth: "600px",
            width: "100%",
            margin: "auto",
          }}
        >
          <Typography
            fontSize={{ xs: "1.9rem", md: "2.125rem" }}
            gutterBottom
            sx={{
              textAlign: "center",
              color: "white",
              margin: "auto",
              paddingBottom: "5px",
            }}
          >
            Schedule Your Demo
          </Typography>
          <div style={{ display: "flex" }}>
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                fontSize: "14px",
                lineHeight: "19px",
                textAlign: "center",
                color: "#FDFDFF",
                opacity: 0.8,
                margin: "auto",
              }}
            >
              Kindly provide your email to arrange a demonstration at your
              convenience.
            </Typography>
          </div>

          <InputSubscription />
        </Box>
        <img
          src={Union}
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            maxWidth: "100%",
            height: "auto",
          }}
          alt="union"
        />
      </Box>
    </Box>
  );
};
export default Schedule;
