import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

export default function NoMatchPage() {
  React.useEffect(() => {
    document.title = "Tansy Cloud | NoMatchPage";
  }, []);

  const handleGoBack = () => {
    if (window.history.length <= 1) {
      window.location.href = "/";
    } else {
      window.history.back();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} >
            <img
              src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif"
              alt="error page"
              width="auto"
              height={280}
            />
          </Grid>
          <Grid xs={12} sm={6} >
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
            <div class="mt-4">
              <Button variant="contained" onClick={handleGoBack}>
                Back Home
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
