import Service from "../images/Service.svg";
import Rewards from "../images/Rewards.svg";
import Appointment from "../images/Appointment.svg";
import Reminder from "../images/Reminder.svg";
import Communication from "../images/Communication.svg";
import CRM from "../images/CRM.svg";
import CloudDocuments from "../images/CloudDocuments.svg";
import AdminSettings from "../images/AdminSettings.svg";
import Organisation from "../images/Organisation.svg";
import SEODigitalMarketing from "../images/SEODigitalMarketing.svg";
import Accounting from "../images/Accounting.svg";

export const Data = {
  Title: "Automobile Repair & Services",
  subTitle: "Repairing and other services for your automobile",
  Btn: [
   { label: "Back to Solutions", nav: "/solutions/allservices" },
    { label: "Schedule a Demo", nav: "/contact-us" },
  ],
};

export const sectionsData = [
  {
    id: 1,
    title: "Service",
    image: Service,
    items: [
      "With our service module, businesses such as salons, parlors, car washes, auto repair shops, and home services are able to manage their end-to-end operations.",
      "Businesses can enable online appointments for their customers with the service module, along with automatic reminders.",
      "With our rewards module, you can award rewards points, cash backs, or coupons.",
      "It's easy to track customers who are due for recurring services with our service module.",
    ],
  },
  {
    id: 2,
    title: "Rewards",
    image: Rewards,
    items: [
      "Tansy’s mission is to make rewards programs available to all businesses, including small and family owned businesses. Currently, only large shops can operate rewards programs.",
      "Our flexible rewards engine allows you to define rules for coupons, cash back and points",
      "Your customers can login to redeem these coupons or points.",
      "Generate coupons by geo locations, cummulative purchase or single purchase events.",
    ],
  },
  {
    id: 3,
    title: "Appointment",
    image: Appointment,
    items: [
      "Easily manage your schedule, display your services, and allow clients to book and pay from their mobile devices.",
      "One place to manage all team calendars, client attendance, and team availability.",
      "Organize your entire business schedule in one place.",
      "Remind customers about their bookings by SMS and email.",
    ],
  },
  {
    id: 4,
    title: "Reminder",
    image: Reminder,
    items: [
      "It’s easy to track customers who are due for recurring services with our service module.",
      "Make sure that your clients are reminded to make payments via SMS, email, or Whatsapp.",
      "Remind customers about their bookings by SMS and email.",
    ],
  },
  {
    id: 5,
    title: "Communication",
    image: Communication,
    items: [
      "You can customize the status of your order or service to send auto-sms, emails, or WhatsApp messages.",
      "With SMS, Email and WhatsApp templates, you can customize the auto communication content for your clients, leads, and employees.",
      "Communicate new product offers to your customers using communication tools.",
    ],
  },
  {
    id: 6,
    title: "CRM",
    image: CRM,
    items: [
      "Automate processes based on workflow rules that route human tasks, data, and files between people or systems.",
      "Using custom workflows, you can specify who should be added as an assignee to a task when it changes status.",
      "Workflows can track leads, clients, employees and other  business entities as they move through the process.",
      "Unlimited contacts.",
      "SMS, Email and WhatsApp templates support.",
      "CRM analytics.",
    ],
  },
  {
    id: 7,
    title: "Cloud Documents",
    image: CloudDocuments,
    items: [
      "Quotes, invoices, receipts, and orders are automatically stored in your AWS account’s cloud document storage.",
      "Business app.",
      "Searches can be performed on leads, clients, and employees in order to find their documents and images.",
      "Additionally, you can add documents and images to a particular lead, client, employee, or vehicle.",
      "Checks and expense invoices can be added via mobile image capture to invoices or expenses.",
    ],
  },
  {
    id: 8,
    title: "Admin & Settings",
    image: AdminSettings,
    items: [
      "You can assign application logins to your existing employees and track their behavior and usage with the application.",
      "You can create custom entities.",
      "Values for system-wide drop downs can be added, deleted, or updated.",
      "You can add custom fields to selected screens, such as lead management",
      "Screen level permissions can be managed via security groups that are assigned to individual system users.",
    ],
  },
  {
    id: 9,
    title: "Organisation",
    image: Organisation,
    items: [
      "Manage employee information, such as attendance, overtime, leaves, salaries, and loans if any.",
      " Manage your clients, leads, and suppliers in terms of their basic information, workflow participation, images,  communications, payments, schedules, and notes.",
      "Unlimited contacts.",
      "Adding company vehicles allows you to keep track of their expenses and breakdowns of service.",
      "Google Map tracking feature.",
    ],
  },
  {
    id: 10,
    title: "SEO & Digital Marketing",
    image: SEODigitalMarketing,
    items: [
      "We provide SEO services for your website.",
      "We can help you set up your social media accounts on Facebook, Instagram, LinkedIn, Youtube, and Twitter.",
      "Besides managing your Google Adwords campaign, we can also publish frequent articles on your business account social media pages.",
    ],
  },
  {
    id: 11,
    title: "Accounting",
    image: Accounting,
    items: [
      "Make payments to suppliers and accept receivables from clients, and merge multiple invoices into one payment.",
      "Clients can automatically receive payment receipts in PDF format via E-mail or WhatsApp.",
      "The system does have the capability of checking future bank cash levels to prevent check bounces.",
    ],
  },
];
