import EcommerceFrame from "../images/EcommerceFrame.svg";
import EcommerceFrameColor from "../images/EcommerceFrameColor.svg";
import EcommerceIcon from "../images/EcommerceIcon.svg";
import OrderManagementFrame from "../images/OrderManagementFrame.svg";
import OrderManagementFrameColor from "../images/OrderManagementFrameColor.svg";
import OrderManagementIcon from "../images/OrderManagementIcon.svg";
import WorkFlowManagementFrame from "../images/WorkFlowManagementFrame.svg";
import WorkFlowManagementFrameColor from "../images/WorkFlowManagementFrameColor.svg";
import WorkFlowManagementIcon from "../images/WorkFlowManagementIcon.svg";
import RewardsFrame from "../images/RewardsFrame.svg";
import RewardsFrameColor from "../images/RewardsFrameColor.svg";
import RewardsIcon from "../images/RewardsIcon.svg";
import CommunicationFrame from "../images/CommunicationFrame.svg";
import CommunicationFrameColor from "../images/CommunicationFrameColor.svg";
import CommunicationIcon from "../images/CommunicationIcon.svg";
import CloudDocumentsFrame from "../images/CloudDocumentsFrame.svg";
import CloudDocumentsFrameColor from "../images/CloudDocumentsFrameColor.svg";
import CloudDocumentsIcon from "../images/CloudDocumentsIcon.svg";
import AccountingFrame from "../images/AccountingFrame.svg";
import AccountingFrameColor from "../images/AccountingFrameColor.svg";
import AccountingIcon from "../images/AccountingIcon.svg";
import InventoryFrame from "../images/InventoryFrame.svg";
import InventoryFrameColor from "../images/InventoryFrameColor.svg";
import InventoryIcon from "../images/InventoryIcon.svg";
import CRMFrame from "../images/CRMFrame.svg";
import CRMFrameColor from "../images/CRMFrameColor.svg";
import CRMIcon from "../images/CRMIcon.svg";

import FoodRestaurantsFrame from "../images/FoodRestaurantsFrame.svg";
import FoodRestaurantsIcon from "../images/FoodRestaurantsIcon.svg";
import SalesFrame from "../images/SalesFrame.svg";
import SalesIcon from "../images/SalesIcon.svg";
import ServicesRepairsFrame from "../images/ServicesRepairsFrame.svg";
import ServicesRepairsIcon from "../images/ServicesRepairsIcon.svg";
import B2BFrame from "../images/B2BFrame.svg";
import B2BIcon from "../images/B2BIcon.svg";
import OutpatientFrame from "../images/OutpatientFrame.svg";
import OutpatientIcon from "../images/OutpatientIcon.svg";
import LeasingRentalsFrame from "../images/LeasingRentalsFrame.svg";
import LeasingRentalsIcon from "../images/LeasingRentalsIcon.svg";
import ResalesFrame from "../images/ResalesFrame.svg";
import ResalesIcon from "../images/ResalesIcon.svg";
import ClubsNonProfitsFrame from "../images/ClubsNonProfitsFrame.svg";
import ClubsNonProfitsIcon from "../images/ClubsNonProfitsIcon.svg";
import AutomobileRepairFrame from "../images/AutomobileRepairFrame.svg";
// import AutomobileRepairIcon from "../images/AutomobileRepairIcon.svg";

export const sectionsData = [
  {
    frame: EcommerceFrame,
    frameColor: EcommerceFrameColor,
    frameIcon: EcommerceIcon,
    title: "E commerce",
    text: "Customize storefront, user-friendly interface, secure payments. Mobile-ready, real-time inventory. Boost sales, global reach, easy setup.",
  },
  {
    frame: OrderManagementFrame,
    frameColor: OrderManagementFrameColor,
    frameIcon: OrderManagementIcon,
    title: "Customer Survey",
    text: "Gain insights with our Survey Software: create surveys, engage users, get real-time responses, and make data-driven decisions.",
  },
  {
    frame: RewardsFrame,
    frameColor: RewardsFrameColor,
    frameIcon: RewardsIcon,
    title: "Loyalty program",
    text: "Boost repeat business: flexible rewards, points, easy redemption, real-time tracking. Elevate engagement.",
  },
  {
    frame: WorkFlowManagementFrame,
    frameColor: WorkFlowManagementFrameColor,
    frameIcon: WorkFlowManagementIcon,
    title: "Work Flow Management",
    text: "Automate tasks, collaborate in real-time, track and centralize documents, make data-driven decisions, optimize workflows.",
  },
  {
    frame: CommunicationFrame,
    frameColor: CommunicationFrameColor,
    frameIcon: CommunicationIcon,
    title: "Communication",
    text: "Teamwork and client communiction via SMS, email, WhatsApp. Centralize channels, automate updates, elevate connections.",
  },
  {
    frame: CloudDocumentsFrame,
    frameColor: CloudDocumentsFrameColor,
    frameIcon: CloudDocumentsIcon,
    title: "Cloud Documents",
    text: "Collaborate, manage docs securely. Cloud access, organize files. Boost productivity. Join for seamless document management.",
  },
  {
    frame: InventoryFrame,
    frameColor: InventoryFrameColor,
    frameIcon: InventoryIcon,
    title: "Sales & Inventory",
    text: "Track multi-location stocks, auto-reorders, barcode accuracy. Integrate sales, cloud-based control. Optimize operations.",
  },
  {
    frame: AccountingFrame,
    frameColor: AccountingFrameColor,
    frameIcon: AccountingIcon,
    title: "Accounting",
    text: "Automate bookkeeping, expenses. Data-driven decisions, tax compliance. Simplify payments and receivables.",
  },
  {
    frame: CRMFrame,
    frameColor: CRMFrameColor,
    frameIcon: CRMIcon,
    title: "CRM",
    text: "Centralize customer data. Manage leads, automate marketing, fuel growth with CRM solution.",
  },
];

export const BusinessData = [
  {
    frame: FoodRestaurantsFrame,
    frameIcon: FoodRestaurantsIcon,
    title: "Restaurants",
    text: "Online Orders, explore diverse menus, seamless checkout, doorstep delivery. Streamline table reservations, manage orders, and track inventory effortlessly.",
  },
  {
    frame: SalesFrame,
    frameIcon: SalesIcon,
    title: "Online Sales",
    text: "Simplify product setup, optimize inventory management, monitor sales, and streamline order processing for improved operational efficiency.",
  },
  {
    frame: ServicesRepairsFrame,
    frameIcon: ServicesRepairsIcon,
    title: "Service Industry",
    text: "Simplify appointments, inventory, and client management, elevating your business to new heights.",
  },
  {
    frame: B2BFrame,
    frameIcon: B2BIcon,
    title: "B2B",
    text: "Efficiently manage inventory, track sales, and simplify order processing for smoother operations.",
  },

  {
    frame: LeasingRentalsFrame,
    frameIcon: LeasingRentalsIcon,
    title: "Asset Management",
    text: "Streamline tenant management, asset tracking, optimize maintenance, and make informed decisions for your asset-driven business success.",
  },
  {
    frame: OutpatientFrame,
    frameIcon: OutpatientIcon,
    title: "Clinics",
    text: "Streamline appointment scheduling, patient records, and billing for efficient operations.",
  },
  {
    frame: ResalesFrame,
    frameIcon: ResalesIcon,
    title: "Real Estate",
    text: "Manage properties, streamline transactions, engage clients, and stay ahead in the competitive market.",
  },
  {
    frame: ClubsNonProfitsFrame,
    frameIcon: ClubsNonProfitsIcon,
    title: "NGO",
    text: "Streamline membership management, event planning, class schedule, and communication for seamless coordination.",
  },

  {
    frame: AutomobileRepairFrame,
    frameIcon: ServicesRepairsIcon,
    title: "More SMEs . . .",
    text: "Our software can be customized for recruitment, manpower supply, event management and publishing. ",
  },
];
