import React, { useState, useEffect } from "react";
import { Box, Button, Container } from "@mui/material";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CardOverlay = ({ data, image }) => {
  const navigate = useNavigate();
  const { Btn, Title, subTitle } = data;
  const [activeButtonIndex, setActiveButtonIndex] = useState(1);
  const [showButtons, setShowButtons] = useState(false);

  const isScreenSmall = useMediaQuery("(min-width:450px)");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButtons(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{ position: "relative" }}
        className="bg-dark text-white border-0 border"
      >
        <img
          src={image}
          className="card-img img-fluid"
          alt="Service"
          style={{ opacity: 0.3, minHeight: "300px" }}
        />
        <Container
          className="card-img-overlay h-100"
          padding="auto"
          margin="auto"
        >
          <Row className="justify-content-center h-100">
            <Col
              md={8}
              className="d-flex flex-column justify-content-center"
              style={{ maxWidth: 700, height: "auto", padding: "auto" }}
            >
              <Typography
                className="mb-2 mb-md-4 pt-2"
                variant="h4"
                gutterBottom
                sx={{ fontSize: isScreenSmall ? "2.125rem" : "1.8rem" }}
              >
                {Title}
              </Typography>
              <Typography
                className="mb-2 mb-md-4"
                variant="subtitle1"
                gutterBottom
                sx={{ lineHeight: isScreenSmall ? "1.75" : ": 1.5" }}
              >
                {subTitle}
              </Typography>
              <Grid
                container="true"
                display="flex"
                spacing={1}
                // className="mb-4"
                justifyContent="center"
              >
                {showButtons && (
                  <Box
                    style={{ padding: "12px" }}
                    className="d-flex justify-content-center "
                    spacing={1}
                  >
                    {Btn.map((button, index) => {
                      const isButtonActive = activeButtonIndex === index;
                      const variant = isButtonActive ? "contained" : "outlined";
                      const scale = isButtonActive ? 1.1 : 1;
                      return (
                        <Box key={index} p={1}>
                          <Button
                            variant={variant}
                            sx={{
                              padding: "10px 30px",
                              marginRight: "18px",
                              borderRadius: "37px",
                              color: "white",
                              borderColor: "white",
                              minWidth: "150px",
                              "@media (max-width: 576px)": {
                                fontSize: "0.65rem",
                                padding: "10px 20px",
                                marginRight: "auto",
                              },
                              transition: "transform 0.9s ease",
                              transform: `scale(${scale})`,
                            }}
                            onMouseEnter={() => setActiveButtonIndex(index)}
                            onMouseLeave={() => setActiveButtonIndex(1)}
                            onClick={() => navigate(button.nav)}
                          >
                            {button.label}
                          </Button>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Grid>
            </Col>
          </Row>
        </Container>
      </Box>
    </Box>
  );
};
export default CardOverlay;
