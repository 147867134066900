import Rewards from "../images/Rewards.svg";
import Communication from "../images/Communication.svg";
import CRM from "../images/CRM.svg";
import CloudDocuments from "../images/CloudDocuments.svg";
import AdminSettings from "../images/AdminSettings.svg";
import Organisation from "../images/Organisation.svg";
import SEODigitalMarketing from "../images/SEODigitalMarketing.svg";
import OrderManagement from "../images/OrderManagement.svg";
import PaymentGateway from "../images/PaymentGateway.svg";
import Inventory from "../images/Inventory.svg";
import Accounting from "../images/Accounting.svg";
import Presales from "../images/Presales.svg";
import Sales from "../images/Sales.jpg";
import PostSales from "../images/PostSales.svg";
import SupportTicket from "../images/SupportTicket.svg";
import OnlineShoppingCart from "../images/OnlineShoppingCart.svg";
import FoodOrders from "../images/FoodOrders.svg";
import Tracking from "../images/Tracking.jpg";

export const Data = {
  Title: "Dining E-Commerce Solution",
  subTitle:
    "Elevate your F&B business with our intuitive software. Manage orders, delivery, inventory, reservations, and boost customer satisfaction.",
  Btn: [
    { label: "Back to Solutions", nav: "/solutions/allservices" },
    { label: "Schedule a Demo", nav: "/contact-us" },
  ],
};

export const sectionsData = [
  {
    id: 1,
    title: "Admin & Security",
    image: AdminSettings,
    items: [
      "Total control ensures authorized access to sensitive information.",
      "Manage screen permissions through security groups for system users.",
      "Monitor employee productivity and identifying any unusual behavior.",
      "Values for system-wide drop downs can be added, deleted, or updated.",
      "You can add custom fields to selected screens, such as leads and clients.",
      "Automated backups, ensuring that your critical business data is safe.",
    ],
  },
  {
    id: 2,
    title: "Reports and Dashboards",
    image: PostSales,
    items: [
      "Derive Informed decisions from our reports and dashboards.",
      "Inventory: Stay on top of stock levels and orders.",
      "Sales: Track sales performance, products, and team.",
      "Finance: Monitor revenue, expenses, and ratios.",
      "Customer: Improve retention with behavior insights.",
      "CRM: Organize, analyze, and optimize customer interactions.",
      "Social Media: Gauge engagement and campaign impact.",
      "Web: Understand website traffic and user behavior.",
      "Marketing: Analyze campaigns, conversion rates, and ROI.",
      "Project Management: Monitor progress, resources, and budget.",
    ],
  },
  {
    id: 3,
    title: "Data Science (Predective Analytics)",
    image: Inventory,
    items: [
      "Data science enables precise targeting, resource allocation, and insights.",
      "Segment audience by behavior, enabling precise targeting with data tools.",
      "Churn Prediction: Proactively retain clients with data-driven strategies.",
      "Ensure optimal inventory levels with precise data analysis.",
    ],
  },
  {
    id: 4,
    title: "Organisation",
    image: Organisation,
    items: [
      "Streamline operations and empower your team to achieve more.",
      "Centralize data, automate, and enhance team collaboration.",
      "Efficiently manage client, lead, and supplier details, workflows, and more.",
      "Monitor expenses incurred by departments, employees, clients and vehicles.",
      "Enable the Google Map tracking feature for field agents.",
      "Employees, leads, clients, suppliers, and equipments seamlessly interconnect.",
    ],
  },
  {
    id: 5,
    title: "HR",
    image: SupportTicket,
    items: [
      "Streamline operations and empower your team to achieve more.",
      "Centralize data, automate, and enhance team collaboration.",
      "Efficiently manage client, lead, and supplier details, workflows, and more.",
      "Monitor expenses incurred by departments, employees, clients and vehicles.",
      "Enable the Google Map tracking feature for field agents.",
      "Employees, leads, clients, suppliers, and equipments seamlessly interconnect.",
    ],
  },
  {
    id: 6,
    title: "Workflow (automation)",
    image: Tracking,
    items: [
      "Streamline tasks, reduce manual effort, and save time.",
      "Tailor automation to your unique business processes.",
      "Minimize mistakes and improve accuracy with automated processes.",
      "Track progress, identify bottlenecks, and make informed decisions.",
      "Ocus on strategic initiatives while repetitive tasks run automatically.",
      "Stay competitive with advanced workflow automation.",
      "Maximize resources, cut down on administrative costs, and boost ROI.",
      "Optimize your workflow and experience the benefits today!",
    ],
  },
  {
    id: 7,
    title: "Cloud Documents",
    image: CloudDocuments,
    items: [
      "Seamlessly work together on documents in real-time from anywhere.",
      "Safeguard your valuable documents with state-of-the-art cloud security.",
      "Access your documents on any device, anytime, anywhere.",
      "Scale up your document storage limit to unlimited storage space.",
      "All transactional documents like invoices and receipts are stored in the cloud.",
      "Effortlessly find relevant documents for a client, supplier or employee. ",
    ],
  },
  {
    id: 8,
    title: "Communication (Email, SMS, WhatsApp)",
    image: Communication,
    items: [
      "Streamline organization messaging with our software.",
      "Connect with team and clients anywhere, on any device.",
      "Centralizes communication for easy access and response.",
      "Automated processes deliver messages promptly to the right recipients.",
      "Customize auto templates for SMS, email, and WhatsApp communication.",
      "Automate invoice and payment receipts via SMS, Email, or WhatsApp.",
      "Execute marketing campaigns using SMS and email channels.",
    ],
  },
  {
    id: 9,
    title: "CRM",
    image: CRM,
    items: [
      "Unlock the Power of Relationships with Our CRM Software!",
      "Understand Customer Insights, tailor interactions, and drive growth.",
      "Organize leads, clients, and suppliers effortlessly.",
      "Foster teamwork and share information with ease.",
      "Visualize and manage your sales process, closing deals faster.",
      "Engage clients with personalized communications and offers.",
      "Make data-driven decisions with insightful CRM analytics.",
    ],
  },
  {
    id: 10,
    title: "Sales",
    image: Presales,
    items: [
      "Visualize and manage the entire sales process effortlessly.",
      "Monitor sales performance, trends, and growth opportunities.",
      "Foster teamwork, share information, and close deals faster.",
      "Nurture relationships with personalized interactions.",
      "Accurately project future revenue for strategic planning.",
      "Access crucial sales data on-the-go for increased flexibility.",
    ],
  },
  {
    id: 11,
    title: "Inventory",
    image: Sales,
    items: [
      "Monitor stock levels, locations, and trends in real-time.",
      "Simplify inventory management from procurement to sales.",
      "Optimize inventory levels based on historical data and market trends.",
      "Receive notifications for low stock, expiration dates, and more.",
      "Minimize overstocking, reduce waste, and optimize resources.",
      "Seamlessly manage invenotry across multiple locations.",
      "integrate barcode scanning with our inventory system.",
    ],
  },
  {
    id: 12,
    title: "Accounting",
    image: Accounting,
    items: [
      "Monitor income, expenses, and transactions effortlessly.",
      "Gain a clear view of your financial health, trends, and opportunities.",
      "Simplify record-keeping, reducing manual errors and time spent.",
      "Ensure accurate tax reporting and stay on top of financial regulations.",
      "Grow your business without compromising financial accuracy.",
      "Easily connect with other business tools for a holistic solution.",
      "Minimize accounting costs and improve overall financial efficiency.",
    ],
  },
  {
    id: 13,
    title: "Marketing",
    image: Tracking,
    items: [
      "Plan, execute, and track marketing initiatives seamlessly.",
      "Monitor performance, trends, and ROI for data-driven decisions.",
      "Foster teamwork, share information, and improve campaign effectiveness.",
      "Nurture relationships with personalized marketing interactions.",
      "Stay competitive with modern marketing management tools.",
      "Centralized hub for campaign planning, strategize, collaborate, and set goals.",
    ],
  },
  {
    id: 14,
    title: "Company website",
    image: SEODigitalMarketing,
    items: [
      "Ultimate solution to create a stunning websites and to engage your audience.",
      "Build a professional website that reflects your brand's unique identity to captivate visitors.",
      "Flawless on all devices: desktops, tablets, and smartphones for wider reach.",
      "Eye-catching Templates: Boost your creativity with ready-made templates.",
      "Our software includes built-in SEO tools to help you rank higher in search engines.",
      "Set up an online store and start selling products with ease.",
      "Gain valuable insights into web traffic and user behavior.",
      "Reliable hosting and data security to keep your website safe and running smoothly.",
      "LINK your mutiple websites into a centralized order management unit.",
    ],
  },
  {
    id: 15,
    title: "E-Commerce",
    image: OnlineShoppingCart,
    items: [
      "Create and manage your e-commerce store with ease.",
      "Monitor sales, customer behavior, and trends for informed decisions.",
      "Reach shoppers on any device with a responsive layout.",
      "Tailor the software to fit your unique e-commerce needs.",
      "Safeguard customer data and ensure secure online shopping.",
      "Boost customer engagement with promotions, discounts, and more.",
      "Manage products and stock levels for optimal sales.",
      "Build trust with your customers through secure payment gateways.",
      "Mobile Responsiveness, capture sales from every device.",
      "Invoices can be sent to your clients through SMS, email, or Whatsapp.",
      "LINK your mutiple ecommerce sites into a centralized order management unit.",
      "User-friendly product filter engine tailored to your business, enhances navigation.",
    ],
  },
  {
    id: 16,
    title: "Order Management",
    image: OrderManagement,
    items: [
      "Manage orders effortlessly from placement to fulfillment.",
      "Monitor order status, stock levels, and delivery in one platform.",
      "Tailor the software to fit your unique order management needs.",
      "Create workflow automation to mange your orders as per business needs.",
      "Real-time order tracking for customer visibility at every step.",
      "Automatic order updates and shipping notifications,",
      "Stay competitive with modern order management technology.",
    ],
  },
  {
    id: 17,
    title: "Delivery Management",
    image: FoodOrders,
    items: [
      "Optimize delivery routes for timely and cost-effective shipping.",
      "Monitor delivery status, location, and ETA in one platform.",
      "Keep customers informed with automated delivery updates.",
      "Handle growing delivery demands without compromising efficiency.",
      "Assign deliveries and manage resources for maximum efficiency.",
      "Expand delivery range from 20 to over 100 kilometers.",
      "Consolidate orders for long-distance deliveries.",
      "Capture digital signatures and photos as proof of delivery.",
    ],
  },
  {
    id: 18,
    title: "Loyalty Program",
    image: Rewards,
    items: [
      "Easily set up and manage loyalty rewards for your customers.",
      "Flexible rewards engine to define coupons, cash back and points.",
      "Monitor program effectiveness, customer engagement, and trends.",
      "Tailor the software to fit your unique loyalty program needs.",
      "Nurture relationships with personalized offers and incentives.",
      "Simplify reward redemption for a seamless customer experience.",
      "Stay competitive with modern loyalty program technology.",
      "Implement a rewarding program for customer loyalty.",
    ],
  },
  {
    id: 19,
    title: "Referrals",
    image: PaymentGateway,
    items: [
      "Effortlessly launch and manage a powerful referral program.",
      "Monitor referral activity, rewards, and program effectiveness.",
      "Empower your customers to refer friends and family.",
      "Expand your customer base through effective referrals.",
      "Simplify reward distribution for a seamless referral experience.",
      "Stay competitive with modern referral program technology.",
    ],
  },
  {
    id: 20,
    title: "Customer Survey",
    image: Accounting,
    items: [
      "Easily design and deploy customer surveys.",
      "Connect with your audience, understand their needs.",
      "Gather client feedback and make data-driven decisions.",
      "Effortless surveys, deep customer insights.",
      "Diverse customer segmentation for precise targeting.",
    ],
  },
  {
    id: 21,
    title: "Vehicle Management",
    image: Organisation,
    items: [
      "Optimize Your Fleet with Our Vehicle Management Software!",
      "Monitor vehicles, maintenance, and performance in one platform.",
      "Stay informed on vehicle locations, fuel consumption, and more.",
      "Automate maintenance scheduling to extend vehicle lifespan.",
    ],
  },
  {
    id: 22,
    title: "Asset Management",
    image: PostSales,
    items: [
      "Effortlessly manage assets from acquisition to retirement.",
      "Monitor asset status, location, and performance in a single platform.",
      "Stay proactive with automated maintenance scheduling.",
      "Streamline warranty registrations, claims, and approvals.",
      "Easily conduct audits and ensure compliance with ease.",
      "Maximize asset utilization and minimize costs.",
    ],
  },
  {
    id: 23,
    title: "Restaurant",
    image: FoodOrders,
    items: [
      "Streamline every aspect of your restaurant, from orders to inventory.",
      "Manage online roders, packaging and delivery.",
      "Optimize seating and reservations for a smooth guest experience.",
      "Easily update online menus, track items, and highlight specials.",
      "Efficiently manage stock levels, reduce wastage, and optimize costs.",
      "Gain insights into sales, customer preferences, and trends.",
      "Monitor orders from kitchen to delivery, ensuring accuracy and speed.",
      "Enhance customer relationships with loyalty programs and offers.",
      "Safeguard transactions with our robust payment processing.",
      "We do not shre your revenue !!!",
    ],
  },
];
