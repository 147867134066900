import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Paper } from "@mui/material";
import CardOverlay from "../../components/CardOverlay/CardOverlay";
import Schedule from "../../components/Schedule/Schedule";
import KeyFeature from "../../components/KeyFeature/KeyFeature";
import WhyChoose from "../../components/WhyChoose/WhyChoose";
import { Data, sectionsData } from "../../data/B2B";
import Img from "../../images/B2B.jpg";

function Index() {
  React.useEffect(() => {
    document.title = "Tansy Cloud | B2B";
  }, []);
  return (
    <React.Fragment>
      <CssBaseline />
      <CardOverlay data={Data} image={Img} />
      <Box margin="10px">
        <Paper elevation={3} sx={{ paddingTop: "10px", paddingLeft: "5px" }}>
          <Box
            sx={{
              p: 2,
              margin: "auto",
              maxWidth: 950,
              flexGrow: 1,
              backgroundColor: "#fff",
            }}
          >
            <KeyFeature data={sectionsData} />
            <WhyChoose />
          </Box>{" "}
          <Schedule />
          <Box paddingBottom="10px" />
        </Paper>
      </Box>
    </React.Fragment>
  );
}
export default Index;
