import MohammadSafderAli from "../images/MohammadSafderAli.png";
import ShabeerAliMohammed from "../images/ShabeerAliMohammed.png";
import MohammadSalman from "../images/MohammadSalman.png";
import AdepuSrikanth from "../images/AdepuSrikanth.png";
import ShafiHussain from "../images/ShafiHussain.png";
import MohammadArifkhan from "../images/MohammadArifkhan.png";
import MkSwamy from "../images/MkSwamy.png";
import Uday from "../images/Uday.png";

export const Data = {
  title: "Who are we",
  script:
    "Behind Tansy Cloud is a diverse team of experts, each bringing a unique perspective and skill set to the table. From our visionary founder to our talented developers, designers, and support staff, every member of our team shares a common goal: High-end technology should be within the financial reach of small and medium businesses.",
};
export const Mission = {
  title: "Our Mission",
  script1: `
    High-end technology should be within the financial reach of small and medium businesses, ensuring that IT skills limitations don't hinder their digital transformation. Consolidate all necessary tools under the umbrella of Tansy Cloud to prevent anyone from being excluded from digitization. The ongoing endeavor involves progressively digitizing business processes to enhance quality and customer service. `,
  script2: ` As we advance the digitization of small and medium businesses, we also assist community members in kickstarting their software careers.
  `,
  more: "More on this at ",
  site: "tansyacademy.com",
};

export const BusinessData = [
  {
    photo: MohammadSafderAli,
    title: "Architect",
    name: "Mohammad Safder Ali",
    nav: "https://www.linkedin.com/company/tansy-cloud/",
  },
  {
    photo: MohammadSalman,
    title: "Senior DevOps Engineer",
    name: "Mohammad Salman",
    nav: null,
  },
  {
    photo: AdepuSrikanth,
    title: "Software Engineer (Backend Lead)",
    name: "Adepu Srikanth",
    nav: "https://www.linkedin.com/in/srikanth-adepu-7a3642132/",
  },
  {
    photo: Uday,
    title: "Software Engineer (FrontEnd Lead)",
    name: "Uday Pothu",
    nav: "https://www.linkedin.com/in/uday-pothu/",
  },
  {
    photo: MohammadArifkhan,
    title: "Delivery Manager",
    name: "Mohammad Arif khan",
    nav: null,
  },
  {
    photo: MkSwamy,
    title: "QA Manager",
    name: "M k Swamy",
    nav: null,
  },
  {
    photo: ShafiHussain,
    title: "UX & Product Developer",
    name: "Shafi Hussain",
    nav: null,
  },

  {
    photo: ShabeerAliMohammed,
    title: "Global Support",
    name: "Shabeer Ali Mohammed",
    nav: null,
  },
];
