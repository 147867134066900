import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Paper } from "@mui/material";
import { sectionsData } from "../../data/AllServices";
import Schedule from "../../components/Schedule/Schedule";
import KeyFeature from "../../components/KeyFeature/KeyFeature";
import WhyChoose from "../../components/WhyChoose/WhyChoose";
import all_services from "../../images/AllServices.jpg";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const services = [
  { name: "Online Sales", navlink: "/solutions/online-sales" },
  { name: "Service Industry", navlink: "/solutions/service-industry" },
  { name: "Restaurant", navlink: "/solutions/restaurant" },
  { name: "Clinics", navlink: "/solutions/clinic" },
  { name: "NGO", navlink: "/solutions/ngo" },
  { name: "B2B", navlink: "/solutions/b2b" },
  { name: "Real Estate", navlink: "/solutions/real-estate" },
  { name: "Asset Management", navlink: "/solutions/asset-management" },
  { name: "BlockChain", navlink: "/solutions/blockchain" },
];

function OverlayData() {
  React.useEffect(() => {
    document.title = "Tansy Cloud | AllServices";
  }, []);
  const navigate = useNavigate();
  return (
    <div className="align-center">
      <Typography
        padding="14px 5px 0px 5px"
        fontSize={{ xs: "1.8rem", md: "2.125rem" }}
        lineHeight={{ xs: "1.5", md: "1.5" }}
        gutterBottom
      >
        Tansy Cloud SaaS Platform
      </Typography>
      <Typography
        className="mb-4"
        //  lineHeight={{ xs: "1.6", md: "1.75" }}
        variant="subtitle1"
        gutterBottom
      >
        Tansy Cloud provides the following Software modules to scale-up &
        automate your business
      </Typography>
      <Grid
        container="true"
        spacing={1}
        className="mb-4"
        justifyContent="center"
      >
        {services.map((service) => (
          <Box
            padding={{ xs: "6px", sm: "8px", md: "10px", lg: "12px" }}
            key={service.name}
            className="d-flex flex-row justify-content-center"
          >
            <Button
              variant="contained"
              style={{
                borderRadius: "37px",
                transition: "all 0.3s ease-in-out",
                cursor: "pointer",
                padding: "10px 30px",
                ...(window.innerWidth <= 600 && {
                  padding: "10px 25px",
                }),
              }}
              className="rounded-pill style"
              onClick={() => navigate(service.navlink)}
              onMouseEnter={(event) => {
                event.target.style.transform = "translateY(-3px)";
              }}
              onMouseLeave={(event) => {
                event.target.style.transform = "none";
              }}
            >
              {service.name}
            </Button>
          </Box>
        ))}
      </Grid>
    </div>
  );
}

const AllSolutionImgOverlay = () => {
  const [data] = useState("sm");
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up(data));

  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{ position: "relative" }}
        className=" bg-dark text-white border-0 border"
      >
        <img
          src={all_services}
          className="card-img img-fluid"
          alt="all services"
          style={{ opacity: 0.3, minHeight: "375px" }}
        />

        {isMatch ? (
          <Container
            className="card-img-overlay h-100"
            padding="auto"
            margin="auto"
          >
            <Row className="justify-content-center h-100">
              <Col
                md={9.2}
                className="d-flex flex-column justify-content-center"
                style={{ maxWidth: 900, Height: "auto", padding: "auto" }}
              >
                <OverlayData />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container
            className="card-img-overlay h-100"
            padding="auto"
            margin="auto"
          >
            <div className="justify-content-center h-100">
              <Col
                md={8}
                margin="auto"
                className="d-flex flex-column justify-content-center"
                style={{ maxWidth: 700, Height: "auto", padding: "auto" }}
              >
                <OverlayData />
              </Col>
            </div>
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default function Index() {
  return (
    <React.Fragment>
      <CssBaseline />
      <div>
        <AllSolutionImgOverlay />
      </div>
      <Box margin="10px">
        <Paper elevation={3} sx={{ paddingTop: "10px", paddingLeft: "5px" }}>
          <div>
            <Box
              sx={{
                p: 2,
                margin: "auto",
                maxWidth: 950,
                flexGrow: 1,
                backgroundColor: "#fff",
              }}
            >
              <KeyFeature data={sectionsData} />
              <WhyChoose />
            </Box>
            <Schedule />
          </div>
          <Box paddingBottom="10px" />
        </Paper>
      </Box>
    </React.Fragment>
  );
}
