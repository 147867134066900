import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../images/tansylogo.svg";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useCallback } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AcUnitSharpIcon from "@mui/icons-material/AcUnitSharp";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

const PAGES = [
  { menu: "Home", link: "/" },
  { menu: "Solutions", link: "/solutions/allservices" },
  { menu: "Pricing", link: "/pricing" },
  { menu: "FAQs", link: "/faqs" },
  { menu: "Contact", link: "/contact-us" },
  { menu: "About Us", link: "/about-us" },
];

const solutionOption = [
  { menu: "Online Sales", link: "solutions/online-sales" },
  { menu: "Service Industry", link: "solutions/service-industry" },
  { menu: "Restaurant", link: "solutions/restaurant" },
  { menu: "Clinic", link: "solutions/clinic" },
  { menu: "NGO", link: "solutions/ngo" },
  { menu: "B2B", link: "solutions/b2b" },
  { menu: "Asset Management", link: "solutions/asset-management" },
  { menu: "Real Estate", link: "solutions/real-estate" },
  { menu: "Blockchain", link: "solutions/blockchain" },
  { menu: "All Solutions", link: "/solutions/allservices" },
];

const iOS =
  typeof navigator !== "undefined" &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

function HeaderNavBarView() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleMouseEnter = (index) => {
    setSelectedIndex(index);
  };

  const handleMouseLeave = () => {
    setSelectedIndex(-1);
  };

  const textStyle = (index) => ({
    transition: "color 0.3s ease-in-out, transform 0.3s ease-in-out",
    cursor: "pointer",
    padding: "14px 21px",
    transform: selectedIndex === index ? "scale(1.05)" : "none",
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <nav
        className="navbar navbar-expand-lg navbar-dark navbar-style"
        style={{
          background: "#3062BE",
          marginLeft: "auto",
        }}
      >
        <Box
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
          style={{ zIndex: 3 }}
        >
          <Box
            component="ul"
            className="navbar-nav"
            marginRight={7}
            style={{ marginLeft: "auto" }}
          >
            {PAGES.map((page, index) => (
              <Box className="nav-item" key={index}>
                <NavLink
                  className="nav-link"
                  to={page.link}
                  key={page.link}
                  label={page.menu}
                  component="li"
                  value={page.link}
                  style={textStyle(index)}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {page.menu}
                </NavLink>
              </Box>
            ))}
          </Box>
        </Box>
      </nav>
    </React.Fragment>
  );
}

function MenuIconSwipeableDrawer() {
  const [isDrawer, setIsDrawer] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);

  const openDrawer = useCallback(() => {
    setIsDrawer(true);
  }, []);
  const closeDrawer = useCallback(() => {
    setIsDrawer(false);
  }, []);

  const toggleCollapse = useCallback(() => {
    setIsCollapse((prevState) => !prevState);
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (page === "Solutions") {
        toggleCollapse();
      } else {
        closeDrawer();
      }
    },
    [toggleCollapse, closeDrawer]
  );

  return (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={isDrawer}
        anchor="right"
        onOpen={openDrawer}
        onClose={closeDrawer}
        role="presentation"
      >
        <div>
          <AppBar position="sticky-top">
            <Toolbar sx={{ background: "#3062BE", p: "15px" }}>
              <IconButton onClick={closeDrawer}>
                <CloseIcon
                  style={{
                    fill: "#FFFFFF",
                  }}
                />
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
        <Container>
          <List>
            {PAGES.map((page, index) => {
              if (page.menu === "Solutions") {
                return (
                  <React.Fragment key={index}>
                    <ListItemButton
                      onClick={toggleCollapse}
                      component={Link}
                      to={page.link}
                    >
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary={page.menu} />
                      <Button>
                        {isCollapse ? <ExpandLess /> : <ExpandMore />}
                      </Button>
                    </ListItemButton>
                    <Collapse in={isCollapse} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {solutionOption.map((solutionPage, index) => (
                          <ListItemButton
                            key={index}
                            sx={{ pl: 4 }}
                            component={Link}
                            to={solutionPage.link}
                            onClose={closeDrawer}
                            onClick={handlePageClick}
                          >
                            <ListItemIcon>
                              <AcUnitSharpIcon />
                            </ListItemIcon>
                            <ListItemText primary={solutionPage.menu} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              } else {
                return (
                  <ListItemButton
                    key={index}
                    component={Link}
                    to={page.link}
                    onClick={closeDrawer}
                  >
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary={page.menu} />
                  </ListItemButton>
                );
              }
            })}
          </List>
        </Container>
      </SwipeableDrawer>
      <Button
        onClick={openDrawer}
        style={{ zIndex: 3 }}
        sx={{ marginLeft: "auto" }}
      >
        <MenuIcon sx={{ color: "white", marginLeft: "auto" }} />
      </Button>
    </React.Fragment>
  );
}

export default function Index() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("990"));
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <CssBaseline />
      <Box color="white" style={{ zIndex: -1 }}>
        <Toolbar
          sx={{
            background: "#3062BE",
            p: "15px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box paddingLeft={10.5} sx={{ cursor: "pointer" }}>
            <img
              className="h-[26px] w-auto"
              src={logo}
              alt="tansylogo"
              loading={"lazy"}
              onClick={() => navigate("/")}
            />
          </Box>
          <Typography variant="h5">
            <Link
              to="/"
              style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "white",
                marginLeft: "3px",
              }}
            >
              Tansy Cloud
            </Link>
          </Typography>
          {isMatch ? <HeaderNavBarView /> : <MenuIconSwipeableDrawer />}
        </Toolbar>
      </Box>
    </React.Fragment>
  );
}
