import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { ButtonBase, Stack } from "@mui/material";
import { Icon } from "@iconify/react";
import logo from "../../images/tansylogo.png";
import twitterCircleFilled from "@iconify/icons-ant-design/twitter-circle-filled";
import baselineFacebook from "@iconify/icons-ic/baseline-facebook";
import linkedinWithCircle from "@iconify/icons-entypo-social/linkedin-with-circle";
import socialInstagramCircular from "@iconify/icons-typcn/social-instagram-circular";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const categories = [
  {
    id: "category-a",
    links: [
      { text: "Online Sales", path: "/solutions/online-sales" },
      { text: "Service Industry", path: "/solutions/service-industry" },
      { text: "Restaurant", path: "/solutions/restaurant" },
      { text: "Clinics", path: "/solutions/clinic" },
    ],
  },
  {
    id: "category-b",
    links: [
      { text: "NGO", path: "/solutions/ngo" },
      { text: "B2B", path: "/solutions/b2b" },
      { text: "Real Estate", path: "/solutions/real-estate" },
      { text: "Asset Management", path: "/solutions/asset-management" },
    ],
  },
  {
    id: "category-c",
    links: [
      { text: "BlockChain", path: "/solutions/blockchain" },
      { text: "All Solution", path: "/solutions/allservices" },
    ],
  },
  {
    id: "category-d",
    links: [
      { text: "About Us", path: "/about-us" },
      { text: "Contact Us", path: "/contact-us" },
      { text: "FAQs", path: "/faqs" },
    ],
  },
];

function NavigateFacebook() {
  // window.open("https://www.facebook.com/login/", "_blank");
}
function NavigateTwitter() {
  // window.open("https://twitter.com/login/", "_blank");
}
function NavigateInstagram() {
  // window.open("https://www.instagram.com/accounts/login/", "_blank");
}
function NavigateLinkedIn() {
  window.open("https://www.linkedin.com/company/tansy-cloud/", "_blank");
}


const icons = [
  {
    icon: baselineFacebook,
    width: 29,
    height: 29,
    cursor: "pointer",
    nav: NavigateFacebook,
  },
  {
    icon: socialInstagramCircular,
    width: 31,
    height: 31,
    cursor: "pointer",
    nav: NavigateInstagram,
  },
  {
    icon: twitterCircleFilled,
    width: 28,
    height: 29,
    cursor: "pointer",
    nav: NavigateTwitter,
  },
  {
    icon: linkedinWithCircle,
    width: 25,
    height: 28,
    cursor: "pointer",
    nav: NavigateLinkedIn,
  },
];

const Item = styled("footer")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const StyledStack = styled(Stack)({
  color: "#063970",
});
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const APP_NAME = "Tansycloud, Inc.";

function FooterImg() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box direction="column">
      <StyledStack>
        <Item>
          <Grid
            container="true"
            spacing={2}
            columns={16}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <ButtonBase
              sx={{ width: 75, height: 75 }}
              onClick={() => navigate("/")}
            >
              <Img
                src={logo}
                alt="tansylogo"
                sx={{
                  margin: "auto",
                  display: "flex",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            </ButtonBase>

            <Typography
              variant="h4"
              gutterBottom
              style={{ cursor: "pointer", color: "#063970" }}
              onClick={() => navigate("/")}
            >
              Tansy Cloud
            </Typography>
          </Grid>
        </Item>
      </StyledStack>
      <Box flexDirection="row" justifyContent="center">
        <Grid
          container="true"
          direction="row"
          justifyContent="center"
          alignItems="center"
          item="true"
        >
          <Grid md={5}>
            <Typography
              alignItems="center"
              variant="body1"
              gutterBottom
              sx={{
                color: "black",
                display: "flex",
              }}
            >
              Sharjah Media City, Sharjah, UAE
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {isMatch ? (
        <Box>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            sx={{ color: "black" }}
          >
            {icons.map((item, index) => (
              <Icon
                key={index}
                icon={item.icon}
                width={item.width}
                height={item.height}
                style={{ cursor: item.cursor }}
                onClick={item.nav}
              />
            ))}
          </Stack>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

function FooterIcons() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Item>
      {isMatch ? (
        ""
      ) : (
        <Box>
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            sx={{ color: "black" }}
          >
            {icons.map((item, index) => (
              <Icon
                key={index}
                icon={item.icon}
                width={item.width}
                height={item.height}
                style={{ cursor: item.cursor }}
                onClick={item.nav}
              />
            ))}
          </Stack>
        </Box>
      )}
    </Item>
  );
}

const FooterNavigation = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container="true"
      xs={12}
      md={7}
      lg={8}
      spacing={4}
      alignItems="start"
      pt={{ xs: "0px", sm: "4px", md: "10px" }}
    >
      {categories.map((category) => (
        <Grid key={category.id} xs={3} lg={3}>
          <Item id={category.id}>
            <Box component="ul" aria-labelledby={category.id} sx={{ pl: 1 }}>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                {category.links.map((link) => (
                  <ButtonBase
                    key={link.text}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(link.path)}
                    onMouseOver={(e) => (e.target.style.color = "#063970")}
                    onMouseOut={(e) => (e.target.style.color = "black")}
                  >
                    <Typography
                      style={{
                        textDecoration: "none",
                        color: "black",
                        display: "block",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        paddingRight: "1px",
                      }}
                      sx={{ fontSize: { xs: "11px", sm: "14px", md: "16px" } }}
                    >
                      {link.text}
                    </Typography>
                  </ButtonBase>
                ))}
              </Stack>
            </Box>
          </Item>
        </Grid>
      ))}
    </Grid>
  );
};

function FooterLayout() {
  return (
    <Box sx={{ flexGrow: 1, pt: 2, pr: 2, pl: 2 }}>
      <div sx={{ pb: 0, pt: 2, pr: 1, pl: 1 }}>
        <Grid
          container="true"
          spacing={2}
          alignItems={{ sm: "center", lg: "start" }}
        >
          <Grid xs={12} md={5} lg={4}>
            <FooterImg />
          </Grid>{" "}
          <FooterNavigation />
        </Grid>
        <Grid xs={6}>
          <FooterIcons />
        </Grid>
      </div>
    </Box>
  );
}

export default function Footer() {

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("md"));
   const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <React.Fragment>
      <footer position=" sticky-bottom"  className="footer">
        <CssBaseline />
        <Box sx={{ margin: isMatch ? "10px" : " " }}>
          <Paper
            elevation={3}
            sx={{
              paddingTop: isMatch ? "" : "3px",
              paddingLeft: isMatch ? "5px" : "1px",
              paddingRight: isMatch ? "0px" : "2px",
            }}
          >
            <FooterLayout />
            <Box paddingBottom="10px" />
          </Paper>
        </Box>

        <Paper elevation={3}>
          <Typography variant="caption" gutterBottom>
            Developed by Tansy Software Solution
          </Typography>
        </Paper>

        <div
          style={{
            background: "#328FD6",
            color: "white",
            padding: "8px",
          }}
        >
          <Typography variant="caption" gutterBottom>
            &copy; {new Date().getFullYear()} {`${APP_NAME} `}
            All rights reserved
          </Typography>
        </div>
      </footer>
    </React.Fragment>
  );
}
