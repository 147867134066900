import * as React from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";
import MailIcon from "@mui/icons-material/Mail";
import firebaseConfig from "../../pages/Secure/Config";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, push } from "firebase/database";

export default function InputSubscription() {
  const [data, setData] = React.useState({
    email: "",
    status: "initial",
  });

  React.useEffect(() => {
    const submitFirebaseData = async () => {
      try {
        const firebaseApp = initializeApp(firebaseConfig);
        const analytics = getAnalytics(firebaseApp);
        const database = getDatabase(firebaseApp);

        const eventEventData = {
          eventName: "Mail request",
          ScheduledMail: {
            email: data.email,
          },
          timestamp: new Date().toISOString(),
          Received: false,
        };

        const messageRef = ref(database, "Request for demo");
        await push(messageRef, eventEventData);
        analytics.logEvent("Request for demo", eventEventData);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };

    if (data.status === "loading") {
      submitFirebaseData();
    }
  }, [data.status, data.email]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setData({ ...data, status: "loading" });
      setTimeout(() => {
        setData({ email: "", status: "sent" });
      }, 1500);
    } catch (error) {
      console.error("Error submitting form:", error);
      setData({ email: "", status: "failure" });
    }
  };

  return (
    <div style={{ paddingTop: "20px", maxWidth: "500px", margin: "auto" }}>
      <form onSubmit={handleSubmit} id="demo">
        <FormControl>
          <FormLabel
            style={{
              "--FormLabel-color": "YOUR_PRIMARY_COLOR",
            }}
          >
            {/* Newsletter */}
          </FormLabel>
          <Input
            startDecorator={<MailIcon />}
            style={{
              "--Input-radius": "20px",
              ...(data.status === "loading" && {
                background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }),
            }}
            placeholder={
              data.status === "loading"
                ? "Subscribing..."
                : "mail@tansycloud.com"
            }
            type="email"
            required
            value={data.email}
            onChange={(event) =>
              setData({ email: event.target.value, status: "initial" })
            }
            error={data.status === "failure"}
            endDecorator={
              <Button
                variant="solid"
                color="primary"
                loading={data.status === "loading"}
                type="submit"
              >
                {data.status === "initial" ? "Schedule" : "Send"}
              </Button>
            }
          ></Input>

          {data.status === "failure" && (
            <FormHelperText style={{ color: "YOUR_DANGER_COLOR" }}>
              Oops! something went wrong, please try again later.
            </FormHelperText>
          )}

          {data.status === "sent" && (
            <FormHelperText style={{ color: "YOUR_PRIMARY_COLOR" }}>
              You are all set!
            </FormHelperText>
          )}
        </FormControl>
      </form>
    </div>
  );
}
