import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Box, CardMedia, Grid, Paper, Typography } from "@mui/material";
import { BusinessData, Data, Mission } from "../../data/AboutUs";
import AboutUsFrame from "../../images/AboutUsFrame.svg";
import OurMissionVideo from "../../images/OurMissionVideo.png";
import Decorator from "../../images/Decorator.svg";
import { Placement } from "../Pricing/Pricing";
import CardCover from "@mui/joy/CardCover";
import JoyCard from "@mui/joy/Card";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function MoreOnThis() {
  return (
    <JoyCard style={{ width: "600px", height: "200px", margin: "auto" }}>
      <CardCover margin="auto" padding={5}>
        <video autoPlay loop muted>
          <source
            src="https://res.cloudinary.com/dmu0ydkri/video/upload/v1692505192/video_1080p_nxvwwo.mp4"
            type="video/mp4"
          />
        </video>
      </CardCover>
    </JoyCard>
  );
}

const typographyProps = {
  fontWeight: "bold",
  fontSize: { xs: "30px", md: "45px" },
  textAlign: "center",
  letterSpacing: "-0.03em",
  gutterBottom: true,
};
const typographyStyle = {
  fontSize: { xs: "15px", md: "17px" },
  gutterBottom: true,
  fontWeight: "500px",
  maxWidth: "780px",
  height: "150px",
  textAlign: "center",
  margin: "auto",
  color: "#64607D",
};
function OurMission() {
  return (
    <Box paddingBottom={6} paddingTop={3}>
      <Box marginTop={15}>
        <Typography {...typographyProps}>{Mission.title}</Typography>
      </Box>
      <Box padding={2} marginBottom={3}>
        <Typography {...typographyStyle}>
          {Mission.script1} <br />
          {Mission.script2}
          <br />
          {Mission.more}
          <span
            style={{
              color: "#328FD6",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {Mission.site}
          </span>
        </Typography>
      </Box>
    </Box>
  );
}
function More() {
  return (
    <Box paddingTop={8}>
      <Box
        padding={3}
        style={{
          marginTop: "15px",
          maxWidth: "600px",
          maxHeight: "200px",
          margin: "auto",
          radius: "30px",
        }}
      >
        <Card.Img src={OurMissionVideo} />
      </Box>
    </Box>
  );
}

export default function AboutUs() {
  React.useEffect(() => {
    document.title = "Tansy Cloud | AboutUS";
  }, []);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div>
      <Box margin="10px">
        <Paper elevation={3} padding="10px">
          <Box paddingBottom={10}>
            <Placement title={Data.title} script={Data.script} />
            <WeAre data={BusinessData} />
            <DecoratorImage />
            <OurMission />
            {isMatch ? <MoreOnThis /> : <More />}
          </Box>
        </Paper>
      </Box>
    </div>
  );
}

function CardImage({ src, alt, hovered }) {
  return (
    <Card.Img
      style={{
        filter: hovered ? "brightness(95%)" : "",
        transition: "filter 0.3s ease-in-out",
      }}
      variant="top"
      src={src}
      alt={alt}
      loading="lazy"
    />
  );
}
function DecoratorImage() {
  return (
    <Box
      sx={{
        marginLeft: "auto",
        width: "150px",
        height: "300px",
        marginTop: "-340px",
      }}
    >
      <CardMedia
        className="card-img "
        component="img"
        image={Decorator}
        alt="Img"
        loading="lazy"
        position="relative"
      />
    </Box>
  );
}

function TeamCard({ photo, title, name, nav }) {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Box
      className="d-flex justify-content-center align-items-center text-dark"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: "relative",
        top: hovered ? "-10px" : "0",
        right: hovered ? "-10px" : "0",
        transition: "all 0.3s ease-in-out",
      }}
    >
      <Box
        margin={4}
        className="position-relative mb-2 mt-2"
        style={{
          width: "220px",
          height: "320px",
        }}
      >
        <Card
          style={{
            width: "220px",
            height: "310px",
          }}
          className="border-0"
        >
          <CardImage src={AboutUsFrame} alt={title} hovered={hovered} />
          <Card.ImgOverlay style={{ textAlign: "left" }}>
            <Box padding={0.5}>
              <Box alignItems="center" justifyContent="center" margin="auto">
                <Box
                  style={{
                    width: "110px",
                    height: "110px",
                    margin: "auto",
                  }}
                >
                  <Image photo={photo} alt={title} />
                </Box>
                <Box
                  padding="10px 7px"
                  className="d-flex justify-content-center "
                  style={{
                    marginTop: "18px",
                    marginBottom: "1px",
                  }}
                >
                  <Role title={title} nav={nav} />
                </Box>
                <Card.Text
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    letterSpacing: "-0.03em",
                    display: "flex",
                    justifyContent: "center",
                    color: "#263238",
                  }}
                  className="d-flex justify-content-center"
                >
                  {name}
                </Card.Text>
              </Box>
            </Box>
          </Card.ImgOverlay>
        </Card>
      </Box>
    </Box>
  );
}

export function Role({ title, nav }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="d-flex align-items-center text-black"
      style={{
        transition: "transform 0.3s ease-in-out",
        transform: isHovered ? "scale(1.07)" : "scale(1)",
        marginTop: "15px",
        background: "rgba(50, 143, 214, 0.1)",
        padding: "7px 30px",
        width: "100%",
        height: "44px",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        window.open(nav, "_blank");
      }}
    >
      <Typography
        color="#328FD6"
        style={{
          flexDirection: "row",
          letterSpacing: "-0.01em",
          textAlign: "center",
          fontWeight: "500px",
          fontSize: "13px",
          lineHeight: "22px",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

export function Image({ photo, title }) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Card.Img
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="rounded-circle"
      style={{
        transition: "transform 0.3s ease-in-out",
        transform: isHovered ? "scale(1.06)" : "scale(1)",
        width: "110px",
        height: "110px",
      }}
      src={photo}
      alt={title}
    />
  );
}

export function WeAre({ data }) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        maxWidth: "1100px",
        margin: "auto",
        paddingTop: "30px",
        paddingBottom: "5px",
      }}
    >
      <Grid
        container="true"
        spacing={{ xs: 1, md: 1 }}
        sx={{ justifyContent: "flex-start" }}
      >
        {data.map(({ photo, title, name, nav }, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <TeamCard photo={photo} title={title} name={name} nav={nav} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
